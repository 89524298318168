import React from 'react';
import { TagsTypeRes } from 'src/Models/ResponseModels/Tags';
import SubCategoriesItem from '../CategoriesItem/SubCategoriesItem';

interface IProps {
    tags: TagsTypeRes.ITag[]
}

const SubCategoriesList = ({ tags }: IProps) => {

    return (
        <div className="categories-list">
            <div className="categories-list-row row">
                {
                    tags.map(tag => {
                        return (
                            <div key={tag.tagID} className="categories-list-col col-md-4 col-6">
                                <SubCategoriesItem tag={tag} />
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default SubCategoriesList;