import React, { useState, useEffect } from 'react';
import Button from '../Button/Button';

const ConnectionError = () => {

    const [image, setImage] = useState<string>('');

    useEffect(() => {
        window.idbCustom.loadImage('assets/images/no-connection.png').then((res: string) => {
            setImage(res);
        })
    })

    const handleReloadPage = () => {
        window.location.reload();
    }


    return (
        <div className="error-conn container">
            <div className="row">
                <div className="col-12 text-center">
                    <h1 className="error-conn__title u-font-size-30">No internet connection</h1>
                    <img className="error-conn__image" src={image} alt=""/>
                    <p className="error-conn__text u-font-size-14">
                        While offline, you can still view the pages you've already visited.<br></br>
                        To visit something new or see updated contents please wait until you're back online.
                    </p>
                    <Button 
                        classes="error-conn__button" 
                        icon="faRedoAlt" 
                        withClass={["primary","center"]}
                        clicked={handleReloadPage}
                    >Reload</Button>
                    {/* <p>This webpage is not available. Check your internet connection and try again.</p> */}
                </div>
            </div>
        </div>
    )
}

export default ConnectionError;