import React from 'react';
import history from '../history'
import ScrollToTop from 'react-scroll-up';
import axiosModule from 'axios';
import axiosInstance from '../config/axios';
import ReactGA from 'react-ga';
import { Helmet } from "react-helmet";
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';

import {
  Switch,
  Route,
  Redirect,
  Link,
} from "react-router-dom";

import 'jquery/dist/jquery';
import 'bootstrap/dist/js/bootstrap.bundle';
import { AppContextInterface, AppContextProvider } from "../context";
import PrivateRoute from '../Components/_UI/PrivateRoute';
import '../config/globalFunctions';

import NotificationsPrompt from '../Components/Notifications/NotificationsPrompt';
import Navigation from '../Components/Navigation/Navigation';
import Footer from '../Components/Footer/Footer';

import HomePage from '../Containers/HomePage/HomePage';
import Registration from '../Components/Registration/Registration';
import ForgotPswPage from '../Containers/ForgotPswPage/ForgotPswPage';
import ChangePswPage from '../Containers/ChangePswPage/ChangePswPage';
import Unsubscribe from '../Containers/Unsubscribe/Unsubscribe';

import EventRsvp from '../Containers/Event/EventRsvp';
import GenericPage from '../Containers/GenericPage/GenericPage';

// Manage errors
import PageNotFound from '../Components/Error/PageNotFound';

// Splash screen
import SplashScreen from '../Components/_UI/SplashScreen/SplashScreen';
import SplashScreenPwa from '../Components/SplashScreenPwa/SplashScreenPwa';
import SplashScreenHCPSurvey from '../Components/SplashScreenHCPSurvey/SplashScreenHCPSurvey';
import EditProfile from 'src/Components/EditProfile/EditProfile';
import { MembersTypeRes } from 'src/Models/ResponseModels/Members';
import PdfViewer from 'src/Components/_UI/PdfViewer/PdfViewer';
import handleRefreshForUpdate from 'src/config/pwaCacheDelete';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import HomePageAgenda from 'src/Components/HomePage/HomePageAgenda/HomePageAgenda';
import PersonalizedKneeCare from 'src/Components/KneeSeries/PersonalizedKneeCare';
import RapidRecovery from 'src/Components/RapidRecoverySeries/RapidRecovery';
import CalendarButton from 'src/Components/Calendar/CalendarButton';
import { NotificationManager } from '@mixerpa/mpa-react-components.mpa-ui/dist/containers/notificationManager';
import { showLoader, hideLoader } from 'src/config/loader';
import CheckLoginStatus from 'src/Components/CheckLoginStatus';
import { MpaUi } from '@mixerpa/mpa-react-components.mpa-ui';
import LoginPage from 'src/Containers/LoginPage/LoginPage';



// #IMPORTANT
// React lazy: Create separate bundles, imported only when needed, to increase initial loading speed

const ThirdPartyCongressPromotion = React.lazy(() => import('../Containers/ThirdPartyCongresses/ThirdPartyCongressPromotion'));
const ThirdPartyCongressRequests = React.lazy(() => import('../Containers/ThirdPartyCongresses/ThirdPartyCongressRequests'));

// -- mailchimp
const MailchimpPanel = React.lazy(() => import('../Components/Mailchimp/MailchimpPanel'));

// -- polls
const PollAdmin = React.lazy(() => import('../Containers/Poll/PollAdmin/PollAdmin'));
const PollUser = React.lazy(() => import('../Containers/Poll/PollUser/PollUser'));

// -- user management
const EditProfilePage = React.lazy(() => import('../Containers/EditProfilePage/EditProfilePage'));
const AddUserPage = React.lazy(() => import('../Containers/AddUserPage/AddUserPage'));
const UsersList = React.lazy(() => import('../Containers/UsersList/UsersList'));
const NotificationsManager = React.lazy(() => import('../Containers/NotificationsManager/NotificationsManager'));
const Cvent = React.lazy(() => import('../Containers/Cvent/Cvent'));
const CventGuestsList = React.lazy(() => import('../Components/Cvent/CventGuestsList'));
const Statistics = React.lazy(() => import('../Containers/Statistics/Statistics'));
const CpdPoints = React.lazy(() => import('../Containers/CpdPoints/CpdPoints'));

// Footer legal pages
const LegalNotice = React.lazy(() => import('../Components/Legal/LegalNotice'));
const TermsService = React.lazy(() => import('../Components/Legal/TermsService'));
const PrivacyPolicy = React.lazy(() => import('../Components/Legal/PrivacyPolicy'));
const CookiesNotice = React.lazy(() => import('../Components/Legal/CookiesNotice'));

// Side pages
const WelcomePage = React.lazy(() => import('../Containers/WelcomePage/WelcomePage'));
const AgendaPage = React.lazy(() => import('../Containers/AgendaPage/AgendaPage'));
const NeverstopPage = React.lazy(() => import('../Containers/NeverstopPage/NeverstopPage'));
const BirthdayPage = React.lazy(() => import('../Containers/BirthdayPage/BirthdayPage'));
const RoboticsTeaser = React.lazy(() => import('../Containers/RoboticsTeaser/RoboticsTeaser'));

// Main pages
const CategoryPage = React.lazy(() => import('../Containers/CategoryPage/CategoryPage'));

// Chat - Case forum
const Chat = React.lazy(() => import('../Containers/Chat/Chat'));
const ChatReact = React.lazy(() => import('../Containers/ChatReact/ChatReact'));
// Customizable chat
const GenericChat = React.lazy(() => import('../Containers/ChatReact/GenericChat'));
const CaseForumCalendar = React.lazy(() => import('../Containers/CaseForum/CaseForumCalendar'));
const CaseForumNotificationDisable = React.lazy(() => import('../Containers/CaseForum/CaseForumNotificationDisable'));

const VirtualMeetingPage = React.lazy(() => import('../Containers/VirtualMeetingPage/VirtualMeetingPage'));

const ShoulderClavicleTreatmentSolutions = React.lazy(() => import('../Containers/ShoulderClavicleTreatmentSolutions/ShoulderClavicleTreatmentSolutions'));
const LetsTalkAboutInfection = React.lazy(() => import('../Containers/LetsTalkAboutInfection/LetsTalkAboutInfection'));
const ZbCircle = React.lazy(() => import('../Containers/ZbCircle/ZbCircle'));
const ZBCircleCaseForum = React.lazy(() => import('../Containers/ZbCircle/ZBCircleCaseForum'));
const ProblemSolved2022Page = React.lazy(() => import('../Containers/ProblemSolved2022/ProblemSolved2022Page'));
const ProblemSolved2022Category = React.lazy(() => import('../Containers/ProblemSolved2022/ProblemSolved2022Category'));
const ShoulderSymposiumWhatShouldWeDo = React.lazy(() => import('../Containers/ShoulderSymposiumWhatShouldWeDo/ShoulderSymposiumWhatShouldWeDo'));
const Livestream = React.lazy(() => import('../Components/Livestream/Livestream'));
const SearchPage = React.lazy(() => import('../Containers/SearchPage/SearchPage'));
const LiveTools = React.lazy(() => import('../Containers/LiveTools/LiveTools'));
const SurveyContent = React.lazy(() => import('../Components/Survey/Survey'));
const TestSubtitles = React.lazy(() => import('../Containers/TestSubtitles/TestSubtitles'));

// WEBINAR
const Webinar = React.lazy(() => import('../Containers/Webinar/Webinar'));
const WebinarsList = React.lazy(() => import('../Containers/WebinarsList/WebinarsList'));

// EVENTS
const Event = React.lazy(() => import('../Containers/Event/Event'));
const Content = React.lazy(() => import('../Containers/Content/Content'));

// EXTRAS
const SkillsInPillsPage = React.lazy(() => import('../Containers/SkillsInPillsPage/SkillsInPillsPage'));
const ThirdPartyCongresses = React.lazy(() => import('../Containers/ThirdPartyCongresses/ThirdPartyCongresses'));
const GrantsDonationsPage = React.lazy(() => import('../Containers/GrantsDonationsPage/GrantsDonationsPage'));
const ListOfFellowshipsPage = React.lazy(() => import('../Containers/GrantsDonationsPage/ListOfFellowshipsPage'));
const IndustryLinksPage = React.lazy(() => import('../Containers/IndustryLinksPage/IndustryLinksPage'));
const RegistrationsContest = React.lazy(() => import('../Containers/RegistrationsContest/RegistrationsContest'));
const eBook = React.lazy(() => import('../Containers/TempPage/eBook'));
const milestones = React.lazy(() => import('../Containers/TempPage/milestones'));

// EDUCATIONAL
// -- On-Demand Digital Learning Sessions
const ONDigitalLearningSession = React.lazy(() => import('../Containers/ONDigitalLearningSessions/ONDigitalLearningSessions'));
// -- Robotics
const RoboticsPage = React.lazy(() => import('../Containers/RoboticsPage/RoboticsPage'));
const RoboticsContent = React.lazy(() => import('../Containers/RoboticsPage/RoboticsContent'));
// -- E-learning
const eLearning = React.lazy(() => import('../Containers/eLearning/eLearning'));
const ELearningContentPage = React.lazy(() => import('../Containers/eLearning/ELearningContentPage'));
const ELearningTest = React.lazy(() => import('../Containers/eLearning/ELearningTest'));
// -- Pji-serie
const PjiPage = React.lazy(() => import('../Containers/PjiPage/PjiPage'));
const CovidElectiveSurgery = React.lazy(() => import('../Containers/CovidElectiveSurgery/CovidElectiveSurgery'));
// -- Troelsen PKR series
const OnAirPage = React.lazy(() => import('../Containers/OnAirPage/OnAirPage'));
// -- simple solutions
const SimpleSolutions = React.lazy(() => import('../Containers/SimpleSolutions/SimpleSolutions'));
// -- CMFT
const CMFT = React.lazy(() => import('../Containers/CMFT/CMFT'));
// -- vLearning
const vLearning = React.lazy(() => import('../Containers/TempPage/vLearning'));
const vLearningPending = React.lazy(() => import('../Containers/TempPage/vLearningPending'));
// -- MCT Tutorials
const modernCementicTutorial = React.lazy(() => import('../Containers/TempPage/modernCementicTutorial'));
// -- Knee Arthroplasty Tutorials
const kneeArthoroplastyTutorial = React.lazy(() => import('../Containers/TempPage/kneeArthoroplastyTutorial'));
// -- Zimmer Biomet for Residents
const zimmerBiometForResidents = React.lazy(() => import('../Containers/TempPage/zimmerBiometForResidents'));
// -- 3D Anatomy
const AnatomyLoginPage = React.lazy(() => import('../Containers/3DAnatomy/3DAnatomyLoginPage'));
const AnatomyModelsLibraryPage = React.lazy(() => import('../Containers/3DAnatomy/3DAnatomyModelsLibraryPage'));
const AnatomyModelItemsPage = React.lazy(() => import('../Containers/3DAnatomy/3DAnatomyModelItemsPage'));
const AnatomyRequests = React.lazy(() => import('../Containers/3DAnatomy/3DAnatomyRequests'));

// CLINICAL EVIDENCE
const ClinicalEvidenceMain = React.lazy(() => import('../Containers/ClinicalEvidence/ClinicalEvidenceMain'));
const ClinicalEvidence = React.lazy(() => import('../Containers/ClinicalEvidence/ClinicalEvidence'));

// CASE FORUM ROSA
const CaseForumRosaRequests = React.lazy(() => import('../Containers/CaseForumRosa/CaseForumRosaRequests'));
const CaseForumPage = React.lazy(() => import('../Containers/CaseForumPage/CaseForumPage'));

//EXTRAS PAGE
const ExtrasPage = React.lazy(() => import('../Containers/Extras/ExtrasPage'));

//WOMEN IN ORTHOPEDICS
const WomenInOrthopedicsPage = React.lazy(() => import('../Containers/WomenInOrthopedicsPage/WomenInOrthopedicsPage'));
const WomenInOrthopedicsChat = React.lazy(() => import('../Containers/WomenInOrthopedicsPage/WomenInOrthopedicsChat'));
const WomenInOrthopedicsChatNotification = React.lazy(() => import('../Containers/WomenInOrthopedicsPage/WomenInOrthopedicsChatNotification'));
const WomenInOrthopedicsPM = React.lazy(() => import('../Containers/WomenInOrthopedicsPage/WomenInOrthopedicsPM'));

const PodcastsAndPubblications = React.lazy(() => import('../Containers/PodcastsAndPubblications/PodcastsAndPubblications'));

const WomenInOrthopedicsWebinars = React.lazy(() => import('../Containers/WomenInOrthopedicsWebinars/WomenInOrthopedicsWebinars'));
const FacultyRoom = React.lazy(() => import('../Containers/FacultyPage/FacultyRoom'));
const CalendarEditor = React.lazy(() => import('../Containers/ControlPanel/CalendarEditor'));
const ContentEditor = React.lazy(() => import('../Containers/ControlPanel/ContentEditor'));
const TpcEditor = React.lazy(() => import('../Containers/ControlPanel/TpcEditor'));

const PostLogin = React.lazy(() => import('../Components/PostLogin'));

var CryptoJS = require("crypto-js");
const trackingId = "UA-142497713-1";
ReactGA.initialize(trackingId);

declare global {
  interface Window {
    jwplayer: any;
    idbCustom: any;
    dataLayer: any;
    OneSignal: any;
    sessionLength: number;
    lastApiRequest: any;
    apiKeepAliveTO: any;
    debug: boolean;
  }
}

interface IProps {
  location?: string
}

interface IState {
  contentCategoryID: number
  contentCategoryName: string
  contentID: number
  filterDate: Date | null,
  scriptsLoaded: boolean,
  authToken: string | null,
  userData: MembersTypeRes.IMember | null,
  isLoginModalOpen: boolean,
  isFirstLoginModalOpen: boolean,
  isReviewModalOpen: boolean,
  livePollID: string,
  isNavigationHidden: boolean
  isFooterHidden: boolean,
  isSliderNotificationsHidden: boolean,
  isModalNeverStopOpen: boolean,
}

const structuredJSON = {
  "@context": "http://schema.org",
  "@type": "Corporation",
  "name": "Zimmer Biomet Institute Network",
  "alternateName": "Zimmer Biomet Institute",
  "url": "https://www.zbinetworkemea.com/",
  "logo": "https://www.zbinetworkemea.com/assets/images/zbinst.png",
  "address": {
    "@type": "PostalAddress",
    "addressLocality": "Switzerland",
    "addressRegion": "CH",
    "postalCode": "8404",
    "streetAddress": "Sulzerallee 8"
  },
  "foundingDate": "1927",
  "founder": "Justin O. Zimmer",
  "tickerSymbol": "ZBH (NYSE)",
  "sameAs": [
    "https://www.facebook.com/zimmerbiomet",
    "https://twitter.com/zimmerbiomet",
    "https://www.youtube.com/user/zimmer",
    "https://www.linkedin.com/company/zimmer-biomet-institute-emea"
  ],
  "suborganization": [
    "Educational",
    "Healthcare",
    "Medical training",
    "Medical education",
    "Online education"
  ]
}

class App extends React.Component<IProps, IState> {

  state: IState = {
    contentCategoryID: 0,
    contentCategoryName: '',
    contentID: 0,
    filterDate: null,
    scriptsLoaded: false,          // Render page only when data have been loaded
    authToken: window.storageGetItemValue('Auth-token'),
    userData: null,
    isLoginModalOpen: false,
    isReviewModalOpen: false,
    livePollID: "",
    isFirstLoginModalOpen: window.storageGetItemValue('isFirstAccess') === 'S' ? true : false,
    isNavigationHidden: false,
    isFooterHidden: false,
    isSliderNotificationsHidden: false,
    isModalNeverStopOpen: false,
  }

  // #IMPORTANT: Create indexedDb for storing files and make them available offline
  async UNSAFE_componentWillMount() {

    if (process.env.NODE_ENV === 'development') {
      window.sessionLength = 1;
      window.debug = true;
    } else {
      window.sessionLength = 20;
      window.debug = false;
    }

    window.lastApiRequest = Date.now();
    window.apiKeepAliveTO = setTimeout(this.apiKeepAlive, window.sessionLength * 60 * 1000);

    let dbPromise: any;

    const loadImage = (path: string) => {

      return new Promise((resolve, reject) => {

        var dbRequest = indexedDB.open('images-store');

        dbRequest.onerror = function (event) {
          resolve(path);
        };

        dbRequest.onupgradeneeded = function (event: any) {
          var database = event.target.result;
          database.createObjectStore('img', { keyPath: 'path' });
        };

        dbRequest.onsuccess = function (event: any) {
          var database = event.target.result;
          var transaction = database.transaction(['img'], "readwrite");
          var objectStore = transaction.objectStore('img');
          var objectRequest = objectStore.get(path);

          // Convert the new image in base64, save it and return the result
          objectRequest.onerror = function () {
            let img = new Image()

            img.onload = async function () {
              var b64 = await imageToBase64(path);
              var transaction = database.transaction(['img'], "readwrite");
              var objectStore = transaction.objectStore('img');

              var objectRequestImg = objectStore.put({
                path: path,
                value: b64
              });

              objectRequestImg.onerror = function () {
                resolve(path)
              };

              objectRequestImg.onsuccess = function () {
                resolve(b64)
              };
            };

            img.onerror = function () {
              resolve(path)
            }

            var url = `${process.env.PUBLIC_URL}${path}`;
            img.src = url;
          };

          objectRequest.onsuccess = function () {
            // Checking if image is already loaded into indexedDB
            if (objectRequest.result)
              resolve(objectRequest.result.value);
            else {
              // Otherwise, convert the new image in base64, save it and return the result
              let img = new Image()

              img.onload = async function () {
                var b64 = await imageToBase64(path);
                var transaction = database.transaction(['img'], "readwrite");
                var objectStore = transaction.objectStore('img');

                var objectRequestImg = objectStore.put({
                  path: path,
                  value: b64
                });

                objectRequestImg.onerror = function () {
                  resolve(path)
                };

                objectRequestImg.onsuccess = function () {
                  resolve(b64)
                };
              };

              img.onerror = function () {
                resolve(path)
              }

              var url = `${process.env.PUBLIC_URL}${path}`;
              img.src = url;
            }
          };
        };
      })
    }

    // Manually save a base64 image inside indexedDB
    const saveBase64Image = (path: string, base64: string) => {

      return new Promise((resolve, reject) => {

        var dbRequest = indexedDB.open('images-store');

        dbRequest.onupgradeneeded = function (event: any) {
          var database = event.target.result;
          database.createObjectStore('img', { keyPath: 'path' });
        };

        dbRequest.onsuccess = function (event: any) {
          var database = event.target.result;
          var transaction = database.transaction(['img'], "readwrite");
          var objectStore = transaction.objectStore('img');

          var objectRequestImg = objectStore.put({
            path: path,
            value: base64
          });

          resolve(path);
        }

      })

    }

    // Delete images
    const deleteImage = (path: string) => {

      var dbRequest = indexedDB.open('images-store');

      dbRequest.onupgradeneeded = function (event: any) {
        var database = event.target.result;
        database.createObjectStore('img', { keyPath: 'path' });
      };

      dbRequest.onsuccess = function (event: any) {
        var database = event.target.result;
        var transaction = database.transaction(['img'], "readwrite");
        var objectStore = transaction.objectStore('img');
        var objectRequest = objectStore.delete(path);

        objectRequest.onsuccess = function () {
        }
      }

    }


    // Load files (audio and video)
    // type: mp4, mp3, pdf?
    // category: code that identify the video series (es. skipil)
    // order: order of the episodes in a category
    // link: url to the resource cached
    const loadFile = (path: string, type: string = 'obj', title: string = '', subtitle: string = '', category: string = 'all', order: number, link: string) => {

      return new Promise((resolve, reject) => {

        var dbRequest = indexedDB.open('files-store');

        dbRequest.onerror = function (event) {
          resolve(path);
        };

        dbRequest.onupgradeneeded = function (event: any) {
          // Objectstore does not exist. Nothing to load
          // event.target.transaction.abort();
          var database = event.target.result;
          database.createObjectStore('file', { keyPath: 'path' });
        };

        dbRequest.onsuccess = function (event: any) {
          // console.log("loadFile","onsuccess", event, event.target.result, event.target.result.objectStoreNames);
          var database = event.target.result;
          var transaction = database.transaction(['file'], "readwrite");
          var objectStore = transaction.objectStore('file');
          // var objectRequest = objectStore.get(path);
          // #IMPORTANT: create a cursor to get all buffer pieces of the same file (if splitted)
          let finalBuffer = new ArrayBuffer(0);
          var objectRequest = objectStore.openCursor(IDBKeyRange.bound(path, path + '_99'));

          // File is not cached: save it
          objectRequest.onerror = async function () {
            fileToArrayBuffer(path, type, title, subtitle, category, order, link, database).then(res => { resolve(res) }).catch(e => reject(e));
          };

          // Try to get file
          objectRequest.onsuccess = async function (event: any) {
            var cursor = event.target.result
            if (cursor) {
              if (cursor.value.path.indexOf(path) >= 0) {
                finalBuffer = _appendBuffer(finalBuffer, cursor.value.value)
              }
              cursor.continue();
            } else {
              // no more results, all pieces have been linked together
              // if file is cached get it, otherwise save it
              if (finalBuffer.byteLength > 0) {
                var blobType = 'obj';
                if (type === 'mp4')
                  blobType = 'video/mp4';
                if (type === 'mp3')
                  blobType = 'audio/mp3';
                let blob = new Blob([finalBuffer], { type: blobType })
                resolve(URL.createObjectURL(blob));
              } else {
                fileToArrayBuffer(path, type, title, subtitle, category, order, link, database).then(res => resolve(res)).catch(e => reject(e));
              }
            }
            // Checking if file is already loaded into indexedDB
            // if (objectRequest.result){
            //     var blobType = 'obj';
            //     if(type === 'mp4')
            //       blobType = 'video/mp4';
            //     if(type === 'mp3')
            //       blobType = 'audio/mp3';
            //     let blob = new Blob([objectRequest.result.value], { type: blobType })
            //     resolve(URL.createObjectURL(blob));
            // } else {
            //     fileToArrayBuffer(path, type, title, subtitle, category, order, link, database).then(res => resolve(res)).catch(e => reject(e));
            // }
          };
        };
      })
    }

    // Delete files (AUDIO, VIDEO)
    const deleteFile = (path: string) => {

      return new Promise((resolve, reject) => {

        var dbRequest = indexedDB.open('files-store');

        dbRequest.onupgradeneeded = function (event: any) {
          var database = event.target.result;
          database.createObjectStore('file', { keyPath: 'path' });
        };

        dbRequest.onsuccess = function (event: any) {
          var database = event.target.result;
          var transaction = database.transaction(['file'], "readwrite");
          var objectStore = transaction.objectStore('file');
          var objectRequest = objectStore.delete(IDBKeyRange.bound(path, path + '_99'));

          objectRequest.onsuccess = function () {
            // delete data referred to this file in local storage
            const indexedDBRef = window.storageGetItem('indexedDBRef').value;
            let newIndexedDBRef = indexedDBRef.filter((el: any) => el.path !== path);
            window.storageSetItem('indexedDBRef', newIndexedDBRef);
            resolve('success');
          }

          dbRequest.onerror = function (e) {
            reject('failed');
          }
        }

      })

    }

    const getFile = (path: string) => {

      return new Promise((resolve, reject) => {

        var dbRequest = indexedDB.open('files-store');

        dbRequest.onupgradeneeded = function (event: any) {
          var database = event.target.result;
          database.createObjectStore('file', { keyPath: 'path' });
        };

        dbRequest.onsuccess = function (event: any) {

          try {
            var database = event.target.result;
            var transaction = database.transaction(['file'], "readwrite");
            var objectStore = transaction.objectStore('file');
            // var objectRequest = objectStore.get(path);
            let finalBuffer = new ArrayBuffer(0);
            var objectRequest = objectStore.openCursor(IDBKeyRange.bound(path, path + '_99'));

            objectRequest.onsuccess = async function (event: any) {
              var cursor = event.target.result
              if (cursor) {
                if (cursor.value.path.indexOf(path) >= 0) {
                  finalBuffer = _appendBuffer(finalBuffer, cursor.value.value)
                }
                cursor.continue();
              } else {
                // no more results, all pieces have been linked together
                // if file is cached get it, otherwise save it
                if (finalBuffer.byteLength > 0) {
                  const indexedDBRef = window.storageGetItem('indexedDBRef').value;
                  const file = indexedDBRef.filter((el: any) => el.path === path)[0];
                  if (file) {
                    const type = file.type;
                    var blobType = 'obj';
                    if (type === 'mp4')
                      blobType = 'video/mp4';
                    if (type === 'mp3')
                      blobType = 'audio/mp3';
                    let blob = new Blob([finalBuffer], { type: blobType })
                    resolve(URL.createObjectURL(blob));
                  } else {
                    resolve(null);
                  }
                } else {
                  resolve(null);
                }
              }
            }

            objectRequest.onerror = function () {
              resolve(null);
            }

          } catch (e) {
            resolve(null);
          }

        }

        dbRequest.onerror = function () {
          resolve(null);
        }

      })

    }


    const fileToArrayBuffer = (path: any, type: string, title: string, subtitle: string, category: string, order: number, link: string, database: any) => {

      return new Promise((resolve, reject) => {
        fetch(path)
          .then(response => response.arrayBuffer())
          .then(buffer => {

            var transaction = database.transaction(['file'], "readwrite");
            var objectStore = transaction.objectStore('file');

            try {

              // #IMPORTANT: split file into multiple buffer if too big (> 120MB)
              const bufferSizeBytes = buffer.byteLength;
              const maxBufferSizeBytes = (80 * 1024 * 1024);
              const splits = Math.ceil(bufferSizeBytes / maxBufferSizeBytes);
              for (var i = 0; i < splits; i++) {
                var objectRequestFile = objectStore.put({
                  path: i > 0 ? path + "_" + i : path,
                  type: type,
                  value: buffer.slice(i * maxBufferSizeBytes, (i + 1) * maxBufferSizeBytes),
                });
              }

              objectRequestFile.onerror = function (e: any) {
                reject(e.target.error.name)
              };

              objectRequestFile.onsuccess = function () {
                // Save data related to the file inside localStorage
                const indexedDBRef = window.storageGetItem('indexedDBRef').value;
                const bufferSizeMB = (buffer.byteLength / 1024 / 1024).toFixed(2);
                indexedDBRef.push({
                  path: path,
                  title,
                  subtitle,
                  type,
                  category,
                  order,
                  link,
                  size: bufferSizeMB,
                  added: moment(new Date()).format('YYYY-MM-DD hh:mm')
                })
                window.storageSetItem('indexedDBRef', indexedDBRef);
                var blobType = 'obj';
                if (type === 'mp4')
                  blobType = 'video/mp4';
                if (type === 'mp3')
                  blobType = 'audio/mp3';
                let blob = new Blob([buffer], { type: blobType })
                resolve(URL.createObjectURL(blob))
              };

            } catch (e) {
              console.log(e);
              // @ts-ignore
              reject(e.message)
            }

          }).catch(e => {
            reject(e.message)
          })

      })

    }


    const imageToBase64 = (path: any) => {

      return new Promise(async (resolve, reject) => {
        var res = await fetch(path);
        var blob = await res.blob();
        var reader = new FileReader();

        reader.addEventListener("load", function () {
          resolve(reader.result);
        }, false);

        reader.addEventListener('error', () => {
          resolve(path);
        });

        reader.readAsDataURL(blob);
      });

    }

    const _appendBuffer = (buffer1: ArrayBuffer, buffer2: ArrayBuffer) => {
      var tmp = new Uint8Array(buffer1.byteLength + buffer2.byteLength);
      tmp.set(new Uint8Array(buffer1), 0);
      tmp.set(new Uint8Array(buffer2), buffer1.byteLength);
      return tmp.buffer;
    };


    window.idbCustom = {
      loadImage: loadImage,
      loadFile: loadFile,
      saveBase64Image: saveBase64Image,
      deleteImage: deleteImage,
      deleteFile: deleteFile,
      getFile: getFile,
    }

    // Clean old storage cached values
    // window.storageCleanOldItems();

    this.setState({
      scriptsLoaded: true,
    });
  }


  async componentDidMount() {

    // Save images needed while offline
    window.idbCustom.loadImage('assets/images/no-connection.png');
    // window.idbCustom.loadImage('assets/images/no-conn-video.png');
    window.idbCustom.loadImage('assets/images/video-not-found.png');
    // Refresh cache on iOS when app version change
    window.addEventListener('focus', handleRefreshForUpdate, false)

    window.addEventListener("reloadProfile", this.updateProfileData);

    // Check if my token is expired
    // TODO: create crypted token from API
    const token = window.storageGetItemValue("Auth-token");
    const deviceToken = window.storageGetItemValue("Auth-device-token");
    const email = window.storageGetItemValue("Auth-email");
    const password = window.storageGetItemValue("Auth-password");

    if (deviceToken) {
      const data = {}
      const config = {
        headers: { Authorization: deviceToken }
      }
      try {
        const res = await axiosModule.post(window.apiURL + "Authentication/autoLogin", data, config);
        const response = res.data;
        if (response.status === "success") {
          window.storageSetItem("Auth-token", response.data.token);
          window.storageSetItem("Auth-device-token", response.data.deviceToken);
          // After the autoLogin, we retrive the profile data for the current user.
          // If we don't, we end up in an hybrid situation were the user is logged in but without profile data loaded, can't access most of the site section.
          axiosInstance.get('Members/GetMyProfile').then(res => {
            const response = res.data;
            if (response.status === "success") {
              this.setState({ userData: response.data, isReviewModalOpen: response.data.additionalField04 === 'S' });
            }
          });
        }
      } catch (error) {
        // @ts-ignore
        if (error.response && (error.response.status === 401 || error.response.status === 403)) {
          window.removeStoredUserData();
          this.setState({ authToken: null })
        }
      }
    }

    // #NOTIFICATIONS

    var OneSignal = window.OneSignal || [];

    OneSignal.push(function () {
      let osID: string = (process.env.NODE_ENV === 'development') ? "52a65f0c-be2d-4e89-90e7-2d58c00b0548" : "fe8b5484-f421-4baf-b53c-18df76486574";
      console.log("OneSignal ID", osID);
      OneSignal.init({
        appId: osID,
      });

      OneSignal.showSlidedownPrompt();

      OneSignal.on('subscriptionChange', function (isSubscribed: any) {
        //var memberID = window.storageGetItem("Auth-memberID");

        if (isSubscribed) {
          // The user is subscribed
          OneSignal.getUserId(function (userId: string) {
            axiosInstance.post('Members/ManagePushDevice', { "deviceID": userId, "enabled": true });
          });
        }
        else {
          OneSignal.getUserId(function (userId: string) {
            axiosInstance.post('Members/ManagePushDevice', { "deviceID": userId, "enabled": false });
          });
        }
      });
    });

    // Init indexedDBRef if not exists
    if (!window.storageGetItem('indexedDBRef')) {
      window.storageSetItem('indexedDBRef', []);
    }

    // #IMPORTANT: Google analytics
    this.setAnalyticsPath(history.location.pathname);
    this.setAnalyticsMemberType(window.storageGetItemValue("Auth-memberTypeID") || 0, window.storageGetItemValue("Auth-memberID"));

    history.listen((location, action) => {
      if (document.getElementById("back-nav")) {
        const backButtonNav: HTMLInputElement = document.getElementById("back-nav") as HTMLInputElement;
        if (backButtonNav) {
          if (history.location.key === undefined) {
            backButtonNav.classList.add("back-nav-none")
          } else {
            backButtonNav.classList.remove("back-nav-none")
          }
        }
      }
      this.setAnalyticsPath(location.pathname);
      if ('serviceWorker' in navigator) {
        if (navigator.serviceWorker && navigator.serviceWorker.controller) {
          navigator.serviceWorker.controller.postMessage({ type: "SKIP_WAITING" });
        }
      }
      //this.setAnalyticsMemberType(window.storageGetItemValue("Auth-memberTypeID") || 0, window.storageGetItemValue("Auth-memberID"));
    });

  }

  componentWillUnmount() {
    // remove iOS check version on focus
    window.removeEventListener('focus', handleRefreshForUpdate, false)
  }

  apiKeepAlive = () => {
    const token = window.storageGetItemValue("Auth-token");
    if (window.debug) console.log("App.tsx", "APIKeepAlive called (due inactivity on api calls)")
    if (token !== null) {
      var dateDiff = Date.now() - window.lastApiRequest;
      if (dateDiff > window.sessionLength * 60 * 1000) {
        if (window.debug) console.log("App.tsx", "API KeepAlive call - Changing token!");
        axiosInstance.get('Authentication/TokenRefresh');
      }
    }

    window.apiKeepAliveTO = setTimeout(this.apiKeepAlive, window.sessionLength * 60 * 1000);

  }


  showNavigation = (toggle: boolean) => { this.setState({ isNavigationHidden: !toggle }) }
  showFooter = (toggle: boolean) => { this.setState({ isFooterHidden: !toggle }) }
  showNotifications = (toggle: boolean) => { this.setState({ isSliderNotificationsHidden: !toggle }) }

  renderEvent = () => {
    return <Event
      contentCategoryID={this.state.contentCategoryID}
      contentCategoryName={this.state.contentCategoryName}
    />
  }

  // #IMPORTANT: Google analytics
  setAnalyticsPath = (path: string) => {
    const section = path.split('/')[1];
    ReactGA.set({ 'dimension2': section });
    ReactGA.set({ page: path })
    ReactGA.pageview(path);
  }
  setAnalyticsMemberType = (memberTypeID: number, memberID: number) => {
    const memberTypeDescr = this.getMemberTypeDescr(memberTypeID);
    //console.log("ReactGA Setting dimesion1:" + memberTypeDescr);
    ReactGA.set({ 'dimension1': memberTypeDescr });
    //ReactGA.set({ 'contentGroup1': memberTypeDescr});

    if (memberID > 0) {
      ReactGA.set({ 'userId': memberID });
    }
  }
  getMemberTypeDescr = (memberTypeID: number) => {
    switch (memberTypeID) {
      case 1:
        return "Health Care Professionals"
      case 2:
        return "Zimmer Biomet Team Members"
      case 3:
        return "MPA Staff"
      case 4:
        return "Faculty"
      default:
        return "Guests"
    }
  }

  updateProfileData = () => {

    axiosInstance.get('Members/GetMyProfile').then(res => {
      const profileResponse = res.data;
      if (profileResponse.status === "success") {
        const myProfileData: MembersTypeRes.IMember = profileResponse.data;
        this.setState({ userData: myProfileData })
      } else {
        window.removeStoredUserData();
        console.log("HERE???")
        history.push('/');
      }
    }).catch(e => console.log(e));
  }

  render() {

    const AppContext: AppContextInterface = {
      openEvent: this.handleContentClick,
      setAuthToken: this.handleAuthorization,
      setProfileData: this.handleGetProfile,
      authToken: this.state.authToken,
      isLoginModalOpen: this.state.isLoginModalOpen,
      livePollID: this.handleLivePollID,
      userData: this.state.userData,
    }

    const { userData, authToken } = this.state;

    return (
      this.state.scriptsLoaded ?
        <div className="App">

          <Helmet>
            <script type="application/ld+json">{JSON.stringify(structuredJSON)}</script>
          </Helmet>

          {/* notifications */}
          {this.state.isSliderNotificationsHidden ? null : <NotificationsPrompt authToken={authToken} />}

          <NotificationManager millisecondsToShow={5000} />

          {/* edit data on first access */}
          {this.state.isFirstLoginModalOpen && authToken !== null ? <Registration type="modal" isModalOpened={true} openModal={() => { this.setState({ isFirstLoginModalOpen: false }) }} /> : null}

          <React.Suspense fallback={<div className="page-loader"></div>}>

            {/* edit data - review for new fields */}
            {userData && userData.additionalField04 === 'S' &&
              <EditProfile
                type="review"
                userData={userData}
                isModalOpened={this.state.isReviewModalOpen}
                openModal={() => { this.setState({ isReviewModalOpen: false }) }}
                editFrom="self" />
            }


            <AppContextProvider value={AppContext}>
              {/* header - navigation bar */}
              {this.state.isNavigationHidden ? null : <Navigation clicked={this.handleNavClick} isHidden={this.state.isNavigationHidden} />}

              <CheckLoginStatus />

              {/* content */}
              <Switch>

                <Route path="/welcome" exact component={WelcomePage} />
                <Route path="/" exact component={AgendaPage} />
                <Route path="/login" exact component={LoginPage} />
                <Route path="/post-login" exact component={PostLogin} />
                <PrivateRoute path="/home-page" exact Component={HomePage} />
                <PrivateRoute path="/women-in-orthopedics-pop-up" exact Component={HomePage} />
                <PrivateRoute path="/category/:key" exact Component={CategoryPage} />
                {/* <Route path="/login" component={LoginPage} /> */}
                <Route path="/forgot-password" component={ForgotPswPage} />
                <Route path="/change-password" render={(routeProps: any) => {
                  return <ChangePswPage search={routeProps.location.search} />
                }}></Route>
                <Route path="/unsubscribe" component={Unsubscribe} />

                {/* Clinical Evidence */}
                <Route path="/clinical-evidence" exact><ClinicalEvidenceMain /></Route>
                <PrivateRoute path="/clinical-evidence/publications" Component={ClinicalEvidence} />
                <PrivateRoute path="/clinical-evidence/registries" Component={GenericPage} Props={{ content: 'clinical-registries' }} />

                {/* Registrations Contest */}
                <Route path="/registrations-contest">
                  <RegistrationsContest showFooter={this.showFooter} showNavigation={this.showNavigation} showNotifications={this.showNotifications} />
                </Route>

                {/* legal content */}
                <Route path="/legal-notice"><LegalNotice /></Route>
                <Route path="/terms-of-service"><TermsService /></Route>
                <Route path="/privacy-policy"><PrivacyPolicy /></Route>
                <Route path="/cookies-notice"><CookiesNotice /></Route>

                {/* user management - reserved area */}
                <PrivateRoute path="/edit-profile/:key?" Component={EditProfilePage} />
                <PrivateRoute path="/add-user" Component={AddUserPage} />
                <PrivateRoute path="/users-list" Component={UsersList} />
                <PrivateRoute path="/notifications-manager" Component={NotificationsManager} />
                <PrivateRoute path="/members/import" exact Component={Cvent} />
                <PrivateRoute path="/members/import/:key" Component={CventGuestsList} />
                <PrivateRoute path="/statistics" Component={Statistics} />
                <PrivateRoute path="/mailchimp-panel" Component={MailchimpPanel} />
                <PrivateRoute path="/cpd-points" Component={CpdPoints} />

                {/* search */}
                <PrivateRoute path="/search/:stringToSearch?" Component={SearchPage} />


                {/* EVENTS */}

                {/* Beyond Implants */}
                <PrivateRoute path="/events/beyond-implants-02-2020-vm" Component={VirtualMeetingPage} />
                <PrivateRoute path="/events/beyond-implants-02-2020-livestream" Component={Livestream} />
                <PrivateRoute path="/events/beyond-implants-02-2020/questions" Component={Chat} Props={{ roomID: "5" }} />
                <PrivateRoute path="/events/beyond-implants-02-2020/voting" Component={PollUser} Props={{ page: "live" }} />
                {/* Beyond Implants - TEST */}
                <PrivateRoute path="/events/beyond-implants-02-2020/test-voting" Component={PollUser} Props={{
                  page: "live", pollID: "AEE297CE-843A-4E83-A330-08D7B533A8D6"
                }} />

                {/* avenir */}
                <PrivateRoute path="/events/avenir-virtual-meeting" Component={VirtualMeetingPage} />

                <PrivateRoute path="/events/problem-solved-2022" exact Component={ProblemSolved2022Page} />
                <PrivateRoute path="/events/problem-solved-2022/:categoryFriendlyURL" exact Component={ProblemSolved2022Category} />

                <PrivateRoute path="/events/shoulder-clavicle-treatment-solutions" exact Component={ShoulderClavicleTreatmentSolutions} />

                <PrivateRoute path="/events/lets-talk-about-infection" exact Component={LetsTalkAboutInfection} />
                <PrivateRoute path="/events/zbedge-circle" exact Component={ZbCircle} />
                <PrivateRoute path="/events/shoulder-symposium-what-should-we-do" exact Component={ShoulderSymposiumWhatShouldWeDo} />

                <PrivateRoute path='/events/:categoryFriendlyURL' exact Component={Event} Props={{
                  contentCategoryID: this.state.contentCategoryID,
                  contentCategoryName: this.state.contentCategoryName
                }} />
                <PrivateRoute path="/events/:categoryFriendlyURL/:key" Component={Content} />

                <PrivateRoute path="/content/:key" Component={Content} />

                <PrivateRoute path='/webinars' exact Component={WebinarsList} />
                <PrivateRoute path='/webinars/:key' exact Component={Webinar} />
                <PrivateRoute path='/webinar-survey/:key' exact Component={SurveyContent} />
                <PrivateRoute path='/event-rsvp/:key' exact Component={EventRsvp} />
                <PrivateRoute path='/live-tools' exact Component={LiveTools} />


                <PrivateRoute path='/rosa-knee-user-network' exact Component={CaseForumPage} Props={{ content: 'rosa-knee-user-network' }} />
                {/* list of users to approve */}
                <PrivateRoute path="/rosa-knee-requests" Component={CaseForumRosaRequests} />
                {/* internal video related to rosa */}
                <PrivateRoute path='/rosa-knee-user-network/:key' exact Component={Content} />

                {/* Chat - Case forum */}
                <PrivateRoute path='/case-forum' exact Component={GenericPage} Props={{ content: 'case-forum-enter' }} />
                <PrivateRoute path='/case-forum-calendar' exact Component={CaseForumCalendar} />
                <PrivateRoute path='/case-forum-meniscal-repair' exact Component={CaseForumPage} Props={{ content: 'case-forum-meniscal-repair' }} />
                <PrivateRoute path='/case-forum-the-difficult-glenoid' exact Component={CaseForumPage} Props={{ content: 'case-forum-the-difficult-glenoid' }} />
                <PrivateRoute path='/case-forum-femoral-revision' exact Component={CaseForumPage} Props={{ content: 'case-forum-femoral-revision' }} />
                <PrivateRoute path='/case-forum-how-decide-between-uka-tka' exact Component={CaseForumPage} Props={{ content: 'case-forum-how-decide-between-uka-tka' }} />
                <PrivateRoute path='/case-forum-oxford-cementless-pkr' exact Component={CaseForumPage} Props={{ content: 'case-forum-oxford-cementless-pkr' }} />
                <PrivateRoute path='/case-forum-trabecular-metal-total-ankle' exact Component={CaseForumPage} Props={{ content: 'case-forum-trabecular-metal-total-ankle' }} />
                <PrivateRoute path='/case-forum-total-elbow-arthroplasty' exact Component={CaseForumPage} Props={{ content: 'case-forum-total-elbow-arthroplasty' }} />
                <PrivateRoute path='/case-forum-cervical-arthroplasty' exact Component={CaseForumPage} Props={{ content: 'case-forum-cervical-arthroplasty' }} />
                <PrivateRoute path='/case-forum-zbfr-trauma' exact Component={CaseForumPage} Props={{ content: 'case-forum-zbfr-trauma' }} />
                <PrivateRoute path='/case-forum-complex-cases-in-primary-tka' exact Component={CaseForumPage} Props={{ content: 'case-forum-complex-cases-in-primary-tka' }} />
                <PrivateRoute path='/case-forum-periprosthetic-fractures' exact Component={CaseForumPage} Props={{ content: 'case-forum-periprosthetic-fractures' }} />
                <PrivateRoute path='/case-forum-zb4residents-knee-unicompartimental-oa-treatment-options-with-hto-uka-and-tka-for-the-young-and-active' exact Component={CaseForumPage} Props={{ content: 'case-forum-zb4residents-knee-unicompartimental-oa-treatment-options-with-hto-uka-and-tka-for-the-young-and-active' }} />
                <PrivateRoute path='/case-forum-zbfr-shoulder-arthroplasty-and-sports-medicine' exact Component={CaseForumPage} Props={{ content: 'case-forum-zbfr-shoulder-arthroplasty-and-sports-medicine' }} />
                <PrivateRoute path='/case-forum-superior-capsular-reconstruction' exact Component={CaseForumPage} Props={{ content: 'case-forum-superior-capsular-reconstruction' }} />
                <PrivateRoute path='/case-forum-zbfr-hip-arthroplasty' exact Component={CaseForumPage} Props={{ content: 'case-forum-zbfr-hip-arthroplasty' }} />
                <PrivateRoute path='/case-forum-zbfr-knee-arthroplasty' exact Component={CaseForumPage} Props={{ content: 'case-forum-zbfr-knee-arthroplasty' }} />
                <PrivateRoute path='/case-forum-proximal-humeral-fractures' exact Component={CaseForumPage} Props={{ content: 'case-forum-proximal-humeral-fractures' }} />
                <PrivateRoute path='/case-forum-pre-operative-planning-for-the-challenging-shoulder-arthroplasty' exact Component={CaseForumPage} Props={{ content: 'case-forum-pre-operative-planning-for-the-challenging-shoulder-arthroplasty' }} />
                <PrivateRoute path='/case-forum-rotator-cuff' exact Component={CaseForumPage} Props={{ content: 'case-forum-rotator-cuff' }} />
                <PrivateRoute path='/case-forum-knee-revision' exact Component={CaseForumPage} Props={{ content: 'case-forum-knee-revision' }} />
                <PrivateRoute path='/case-forum-interprosthetic-and-peri-implant-fractures' exact Component={CaseForumPage} Props={{ content: 'case-forum-interprosthetic-and-peri-implant-fractures' }} />
                <PrivateRoute path='/case-forum-personalized-tkr-alignment' exact Component={CaseForumPage} Props={{ content: 'case-forum-personalized-tkr-alignment' }} />
                <PrivateRoute path='/case-forum-pre-op-planning-for-every-reverse-shoulder-arthroplasty' exact Component={CaseForumPage} Props={{ content: 'case-forum-pre-op-planning-for-every-reverse-shoulder-arthroplasty' }} />
                <PrivateRoute path='/case-forum-difficult-glenoid' exact Component={CaseForumPage} Props={{ content: 'case-forum-difficult-glenoid' }} />
                <PrivateRoute path='/case-forum-zb4residents-shoulder-surgical-approach-and-principles-of-arthroplasty' exact Component={CaseForumPage} Props={{ content: 'case-forum-zb4residents-shoulder-surgical-approach-and-principles-of-arthroplasty' }} />
                <PrivateRoute path='/case-forum/:roomID' exact Component={Chat} />
                <PrivateRoute path="/case-forum-notification-disable" Component={CaseForumNotificationDisable} />
                <PrivateRoute path='/case-forum-ZB4Residents-Hip-Instability-of-the-hip-risk-factors-stratification-and-treatment-strategies' exact Component={CaseForumPage} Props={{ content: 'case-forum-zb4residents-hip-Instability-of-the-hip-risk-factors-stratification-and-treatment-strategies' }} />
                <PrivateRoute path='/case-forum-infection-prevention-with-polytrauma-patients' exact Component={CaseForumPage} Props={{ content: 'case-forum-infection-prevention-with-polytrauma-patients' }} />
                <PrivateRoute path='/case-forum-zb4residents-knee-arthroscopy-knee-injuries-and-treatment-options' exact Component={CaseForumPage} Props={{ content: 'case-forum-zb4residents-knee-arthroscopy-knee-injuries-and-treatment-options' }} />
                <PrivateRoute path='/case-forum-zb4residents-trauma-stabilisation-of-proximal-femoral-fractures' exact Component={CaseForumPage} Props={{ content: 'case-forum-zb4residents-trauma-stabilisation-of-proximal-femoral-fractures' }} />
                <PrivateRoute path='/case-forum-shoulder-arthroplastiy-pre-operative-planning-2022' exact Component={CaseForumPage} Props={{ content: 'case-forum-shoulder-arthroplastiy-pre-operative-planning-2022' }} />
                <PrivateRoute path='/case-forum-challenges-in-hip-fracture-management-2022' exact Component={CaseForumPage} Props={{ content: 'case-forum-challenges-in-hip-fracture-management-2022' }} />
                <PrivateRoute path='/case-forum-preventive-strategies-to-reduce-the-risk-of-infection' exact Component={CaseForumPage} Props={{ content: 'case-forum-preventive-strategies-to-reduce-the-risk-of-infection' }} />
                <PrivateRoute path='/case-forum-rib-repair-2022' exact Component={CaseForumPage} Props={{ content: 'case-forum-rib-repair-2022' }} />
                <PrivateRoute path='/case-forum-modern-approaches-to-manage-bone-infection-2023' exact Component={CaseForumPage} Props={{ content: 'case-forum-modern-approaches-to-manage-bone-infection-2023' }} />
                <PrivateRoute path='/case-forum-treatment-options-from-fracture-fixation-to-ankle-replacement' exact Component={CaseForumPage} Props={{ content: 'case-forum-treatment-options-from-fracture-fixation-to-ankle-replacement' }} />
                <PrivateRoute path="/case-forum-zbedge-circle" exact Component={ZBCircleCaseForum} />
                <PrivateRoute path='/case-forum-oxford-pka-2024' exact Component={CaseForumPage} Props={{ content: 'case-forum-oxford-pka-2024' }} />
                {/* <PrivateRoute path='/case-forum-page/:key' exact Component={CaseForumPage} Props={{ content: '' }} /> */}

                {/* Chat - Live webinar */}
                <PrivateRoute path='/faculty-chat/:key' exact Component={ChatReact} Props={{ showTextArea: false, maxMsgLoaded: 10, liveWebinar: true }} />
                <PrivateRoute path='/user-chat/:key' exact Component={ChatReact} Props={{ showDetails: false, showMessages: false }} />

                {/* TEST POLLS */}
                <PrivateRoute path="/polls-admin" Component={PollAdmin} />
                {/* <PrivateRoute path="/polls-user-live" Component={PollUser} Props={{
              page: "live", pollID: "199A60DF-3C16-4BAD-C892-08D7B3CF1CF7"
            }} /> */}
                {/* <PrivateRoute path="/polls-user-planned" Component={PollUser} Props={{
              page: "planned", pollID: "f0e699e2-b8e1-432b-a9ab-2f2d72b3360f"
            }} /> */}

                {/* EMEA Virtual Hall */}
                <PrivateRoute path="/the-virtual-exhibition-experience" Component={GenericPage} Props={{ content: 'the-virtual-exhibition-experience' }} />
                <PrivateRoute path="/the-virtual-exhibition-experience-vm" Component={VirtualMeetingPage} />

                {/* E-LIBRARY */}
                <PrivateRoute path="/e-library" Component={GenericPage} Props={{ content: 'e-library' }} />

                {/* EXTRAS */}
                <PrivateRoute path="/skills-in-pills/:friendlyURL?/:tabName?" Component={SkillsInPillsPage} />
                <PrivateRoute path="/third-party-congresses/:section?/:item?" Component={ThirdPartyCongresses} />
                <PrivateRoute path="/third-party-congress-promotion" Component={ThirdPartyCongressPromotion} Props={{ userData: userData }} />
                <PrivateRoute path="/third-party-congress-requests" Component={ThirdPartyCongressRequests} Props={{ userData: userData }} />
                <PrivateRoute path="/essenti-app" Component={GenericPage} Props={{ content: 'essenti-app' }} />
                <PrivateRoute path="/e-book" Component={eBook} />
                <PrivateRoute path="/trabecular-metal-tech" Component={GenericPage} Props={{ content: 'trabecular-metal-tech' }} />
                <PrivateRoute path="/milestones/:key" Component={milestones} />
                <PrivateRoute path="/grants-and-donations" Component={GrantsDonationsPage} />
                <PrivateRoute path="/industry-links" Component={IndustryLinksPage} />
                <PrivateRoute path="/list-of-fellowships" Component={ListOfFellowshipsPage} />


                {/* --- EDUCATIONAL --- */}

                {/* 3D Anatomy */}
                <PrivateRoute path="/3d-anatomy" exact Component={AnatomyLoginPage} />
                <PrivateRoute path="/3d-anatomy/model-library" exact Component={AnatomyModelsLibraryPage} />
                {/* key represent the category of model, content the single model */}
                <PrivateRoute path="/3d-anatomy/model-library/:key/:contentID?" Component={AnatomyModelItemsPage} />
                {/* list of users that requested access to 3d anatomy */}
                <PrivateRoute path="/3d-anatomy-requests" Component={AnatomyRequests} />

                <PrivateRoute path="/on-demand-digital-learning-sessions/:categoryID?/:resourceID?" Component={ONDigitalLearningSession} />

                <PrivateRoute path="/patient-outcomes-in-pkr/:tabName?/:item?" Component={OnAirPage} />

                <PrivateRoute path="/elective-surgery-in-the-covid-19-era" Component={CovidElectiveSurgery} />

                <PrivateRoute path="/personalized-knee-care" Component={PersonalizedKneeCare} />

                <PrivateRoute path="/rapid-recovery-series" Component={RapidRecovery} />

                {/* robotics */}
                <PrivateRoute path="/robotics" exact Component={RoboticsPage} />
                <PrivateRoute path="/robotics/:key" exact Component={RoboticsContent} />
                <PrivateRoute path="/robotics/:key/:section" exact Component={RoboticsContent} />
                <PrivateRoute path="/robotics/:key/:section/:resourceID" exact Component={RoboticsContent} />

                <PrivateRoute path="/pji-series/:key?" Component={PjiPage} />

                <PrivateRoute path="/e-learning/:key?" exact Component={eLearning} />
                <PrivateRoute path="/e-learning/content/:key" Component={ELearningContentPage} />
                <PrivateRoute path="/e-learning/quiz/:key" Component={ELearningTest} />

                <PrivateRoute path="/v-learning" Component={vLearning} Props={{ userData: userData }} />
                <PrivateRoute path="/v-learning-pending" Component={vLearningPending} />

                <PrivateRoute path="/modern-cementing-tutorials/:key?" Component={modernCementicTutorial} />

                <PrivateRoute path="/knee-arthoroplasty-tutorials/:key?" Component={kneeArthoroplastyTutorial} />

                <PrivateRoute path="/zimmer-biomet-for-residents/:key?" Component={zimmerBiometForResidents} Props={{ userData: userData }} />

                <PrivateRoute path="/simple-solutions" Component={SimpleSolutions} />

                <PrivateRoute path="/cmf-and-t" Component={CMFT} />

                <PrivateRoute path="/surgical-technique-using-persona-fuzion" Component={GenericPage} Props={{ content: 'surgical-technique-using-persona-fuzion' }} />

                <PrivateRoute path="/surgical-technique-using-nexel-total-elbow" Component={GenericPage} Props={{ content: 'surgical-technique-using-nexel-total-elbow' }} />

                <Route path="/neverstop" component={NeverstopPage} />
                <Route path="/zbin-3rd-anniversary" component={BirthdayPage} />
                <PrivateRoute path="/robotics-teaser" Component={RoboticsTeaser} />


                <PrivateRoute path='/feedback-survey/:key' exact Component={SurveyContent} />
                {/* using resource for robotics */}
                <PrivateRoute path='/robotics-feedback-survey/:key' exact Component={SurveyContent} />


                {/* test subtitles */}
                <PrivateRoute path="/test-subtitles" Component={TestSubtitles} />

                {/* test pdf viewer */}
                <PrivateRoute path="/test-pdf" Component={PdfViewer} />

                {/* Extras */}
                <PrivateRoute path="/extras" exact Component={ExtrasPage} Props={{ typeItem: 'extras' }} />

                {/* Professional Development */}
                <PrivateRoute path="/professional-development" exact Component={ExtrasPage} Props={{ typeItem: 'professional-development' }} />

                {/* Women In Orthopedics*/}
                <PrivateRoute path="/women-in-orthopedics" exact Component={WomenInOrthopedicsPage} />
                <PrivateRoute path="/podcasts-and-publications" exact Component={PodcastsAndPubblications} />
                <PrivateRoute path="/women-in-orthopedics/chat" exact Component={WomenInOrthopedicsChat} />
                <PrivateRoute path="/women-in-orthopedics/chat/disable" exact Component={WomenInOrthopedicsChatNotification} />
                {/* Old link was "/women-in-orthopedics/chat/87" now treated as "/women-in-orthopedics/chat" route */}
                <PrivateRoute path="/women-in-orthopedics/chat/87" exact Component={WomenInOrthopedicsChat} />
                <PrivateRoute path="/women-in-orthopedics/private-message/:userId/:subject" exact Component={WomenInOrthopedicsChat} />
                {/* <PrivateRoute path="/women-in-orthopedics/webinars" exact Component={WomenInOrthopedicsWebinars} /> */}
                <PrivateRoute path="/women-in-orthopedics/webinars/:key?" Component={WomenInOrthopedicsWebinars} />
                {/* <PrivateRoute path="/zimmer-biomet-for-residents/:key?" Component={zimmerBiometForResidents} Props={{ userData: userData }} /> */}

                <PrivateRoute path="/faculty-room/:key?" exact Component={FacultyRoom} />

                <PrivateRoute path="/content-manager" exact Component={ContentEditor} />
                <PrivateRoute path="/calendar-manager" exact Component={CalendarEditor} />
                <PrivateRoute path="/tpc-manager" exact Component={TpcEditor} />

                <Route component={PageNotFound} />

              </Switch>
            </AppContextProvider>

          </React.Suspense>

          {/* scroller */}
          {/* <ScrollToTop showUnder={100} duration={500} style={{ bottom: 0, left: 0, right: 'auto', zIndex: 11 }}>
            <div className="scroll-top">
              <FontAwesomeIcon icon={faChevronUp} />
            </div>
          </ScrollToTop> */}

          {/* calendar icon */}
          <CalendarButton />

          {/* HCPs feedback survey splash screen */}
          {
            userData && userData.memberTypeID === 1 &&
            <SplashScreenHCPSurvey />
          }

          {/* footer  */}
          {this.state.isFooterHidden ? null : <Footer />}
        </div> : null
    );
  }

  handleNavClick = (contentCategoryID: number, contentCategoryName: string) => {
    this.setState({
      contentCategoryID,
      contentCategoryName,
      contentID: 0
    })
    return (
      <Redirect
        to={{
          pathname: `/events/${contentCategoryID}`
        }}
      />
    );
  }

  handleContentClick = (contentID: number) => {
    this.setState({
      contentID,
      contentCategoryID: 0,
      contentCategoryName: ''
    })
  }

  handleLivePollID = (pollID: string) => {
    this.setState({ livePollID: pollID });
  }

  handleGetProfile = (member: MembersTypeRes.IMember) => {
    this.setState({ userData: member, isReviewModalOpen: member.additionalField04 === 'S' });
  }

  // Function called by LoginForm or Navigation component
  // Receive null when login fails or Logout is clicked
  // Receive toke value when user login successfully
  handleAuthorization = async (token: string | null, callbackFn?: Function) => {
    if (token) {
      this.setState({ authToken: token }, () => {
        this.setAnalyticsMemberType(window.storageGetItemValue("Auth-memberTypeID") || 0, window.storageGetItemValue("Auth-memberID"));
        var OneSignal = window.OneSignal || [];
        OneSignal.push(["getNotificationPermission", function (permission: string) {
          if (permission === 'granted') {
            OneSignal.getUserId(function (userId: string) {
              axiosInstance.post('Members/ManagePushDevice', { "deviceID": userId, "enabled": true });
            });
          }
        }]);
        if (window.storageGetItemValue("isFirstAccess") === 'S') {
          this.setState({ isFirstLoginModalOpen: true })
        }
      });
    }
    else {
      this.setState({ authToken: token }, () => { if (callbackFn) callbackFn() });
    }
    // #IMPORTANT: manually launch an event to update the list of cached menu items
    window.dispatchEvent(new CustomEvent('updateMenu'));
  }
}

export default App;
