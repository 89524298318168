import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import useCurrentWidth from 'src/Components/_hooks/useCurrentWidth';
import Button from 'src/Components/_UI/Button/Button';
import HomePageCircle from '../HomePageCircle/HomePageCircle';


const HomePageHeader = () => {
    const [banner, setBanner] = useState<string>('');
    const width = useCurrentWidth();
    
    useEffect(() => {
        if(width <= 768){
            setBanner('assets/images/home/banner-1024-350-mobile.jpg');
        } else setBanner('assets/images/home/banner-1920-460.jpg');
    }, [width])
    
    return (
        <section className="home-welcome">
            {/*
            <HomePageCircle />
            */}
            <div className="home-welcome-content">
                <img src={banner} alt="Zimmer Biomet Institute Network" className="img-fluid home-welcome-content-banner" />
                <Link to="/welcome" className="home-welcome-content__click"></Link>
                {/*
                <header className="home-welcome-content-header">
                    <h1>
                        <p className="u-font-size-20">Welcome to</p>
                        <img src='/assets/images/home/zbinetwork-logo.png' alt="Zimmer Biomet Institute Network" className="img-fluid home-welcome-content-logo" />
                    </h1>
                    <h2 className="u-font-size-12">
                        MEDICAL EDUCATION: <span>THE SMART WAY</span>
                    </h2>
                </header>
                /*
                <div className="home-welcome-content-body">
                    <Link to="/welcome" className="home-welcome-content-link">
                        <Button 
                            withClass={["primary","center"]} 
                            classes="home-welcome-content-read-more font-weight-bold"
                            >READ MORE
                        </Button>
                    </Link>
                    <img src="/assets/images/home/home-icons.jpg" alt="Learn. Interact. Understand. Share." className="home-welcome-content-icons img-fluid" />
                </div>
                */}
            </div>
        </section>
    );

}

export default HomePageHeader;