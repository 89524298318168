import MemberRole from '../Models/Members/MemberRole';

// #IMPORTANT: Fix error "Augmentations for the global scope can only be directly nested in external modules or ambient module declarations."
export { };


declare global {
    interface Window {
        storageSetItem: (key: string, value: any, isPermanent?: boolean) => void,
        storageGetItem: (key: string) => any,
        storageGetItemValue: (key: string) => any,
        storageRemoveItem: (key: string) => void,
        storageCleanOldItems: () => void,
        storageDeleteOldestItem: () => void,
        userInRole: Function;
        getRoles: Function;
        memberIsInRole: Function;
        asyncForEach: Function;
        removeStoredUserData: Function;
        apiURL: string;
    }
}


// ----------------------------
// LOCAL STORAGE
// ----------------------------

window.storageSetItem = (key: string, value: any, isPermanent?: boolean) => {
    var timestamp = new Date().getTime();
    if (key === 'indexedDBRef' || isPermanent) {
        timestamp = new Date('2999-01-01').getTime()
    }
    try {
        localStorage.setItem(key, JSON.stringify({ value: value, timestamp: timestamp }));
    } catch (e) {
        // Quota exceeded: remove oldest API call saved and try again
        window.storageDeleteOldestItem();
        window.storageSetItem(key, value);
    }
}

window.storageGetItem = (key: string) => {
    if (localStorage.getItem(key)) {
        try {
            return JSON.parse(localStorage.getItem(key) || '[]');
        } catch (e) {
            return localStorage.getItem(key);
        }
    } else {
        return null
    }
}

window.storageGetItemValue = (key: string) => {
    const item = window.storageGetItem(key);
    if (item === null) return null;
    if (item.value === undefined) return item;
    try {
        return JSON.parse(item.value);
    } catch (e) {
        return item.value;
    }
}

window.storageRemoveItem = (key) => {
    localStorage.removeItem(key);
}

// Delete all API calls older than 1 week
window.storageCleanOldItems = () => {
    const today = new Date();
    const limitDate = today.setDate(today.getDate() - 7);
    Object.keys(localStorage).forEach(key => {
        const item = window.storageGetItem(key);
        if (item.timestamp === undefined || item.timestamp < limitDate) {
            window.storageRemoveItem(key);
        }
    })
}

window.storageDeleteOldestItem = () => {
    let oldestItem: any = null;
    let oldestItemKey: string = '';
    Object.keys(localStorage).forEach(key => {
        if (key !== 'indexedDBRef' && !key.match(/Auth-*/)) {
            const item = window.storageGetItem(key);
            if (oldestItem === null || oldestItem.timestamp > item.timestamp) {
                oldestItem = item;
                oldestItemKey = key;
            }
        }
    })
    window.storageRemoveItem(oldestItemKey);
}


window.getRoles = (str: string) => {
    let currRoles: MemberRole[] = window.storageGetItemValue("Auth-roles");
    let result: MemberRole[] = [];
    if (currRoles && currRoles.length > 0) {
        result = currRoles.filter(function (el: MemberRole) {
            return el.roleDescription.startsWith(str);
        });
    }

    return result;
}

// Search for current logged user roles and return true if user has the specified role (str)
window.userInRole = (str: string) => {
    let currRoles: MemberRole[] = window.storageGetItemValue("Auth-roles");
    let result: boolean = false;
    if (currRoles && currRoles.length > 0) {
        let search = currRoles.filter(function (el: MemberRole) {
            return el.roleDescription === str;
        });
        result = search.length > 0;
    }

    return result;
}

//Search the given role (str) in the provided role array (memberRoles)
//and return true if role is present
window.memberIsInRole = (memberRoles: MemberRole[], str: string) => {
    let result: boolean = false;
    if (memberRoles && memberRoles.length > 0) {
        let search = memberRoles.filter(function (el: MemberRole) {
            return el.roleDescription === str;
        });
        result = search.length > 0;
    }

    return result;
}

// Custom async/await forEach
window.asyncForEach = async (array: Array<any>, callback: any) => {
    for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
    }
}

//Removes all user information stored in localStorage
window.removeStoredUserData = () => {
    window.storageRemoveItem("Auth-token");
    window.storageRemoveItem("Auth-device-token");
    window.storageRemoveItem("Auth-email");
    window.storageRemoveItem("Auth-password");
    window.storageRemoveItem("Auth-memberID");
    window.storageRemoveItem("Auth-roles");
    window.storageRemoveItem("Auth-memberTypeID");
}


// ----------------------------
// OVERRIDE FUNCTIONS
// ----------------------------

// #IMPORTANT: Manage null promise object (for react-pdf library)
// @ts-ignore
Promise.allSettled = function (promises) {
    let mappedPromises = promises.map((p: any) => {
        try {
            return p
                .then((value: any) => {
                    return {
                        status: 'fulfilled',
                        value
                    };
                })
                .catch((reason: any) => {
                    return {
                        status: 'rejected',
                        reason
                    };
                });
        } catch (e) {
            return {
                status: 'rejected',
                reason: e
            };
        }
    });
    return Promise.all(mappedPromises);
};