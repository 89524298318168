import React, { FormEvent, KeyboardEvent } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import axios from '../../config/axios';
import { validateEmail } from '../../config/functions';
import { Authorization } from '../../Models/RequestModels/Authorization';
import { MembersTypeRes } from '../../Models/ResponseModels/Members';
import { AppContextConsumer } from "../../context";
import history from 'src/history';
import Registration from '../Registration/Registration';
import { useFormik } from 'formik';





var CryptoJS = require("crypto-js");

declare global {
    interface Window {
        email: string,
        password: string
    }
}

interface IProps {
    type: "form" | "modal"
    isModalOpened?: boolean,
    openModal?: (value: boolean) => void,
    location?: any,
    fnOnClose?: (value: boolean) => void,
}

const initError = {
    email: false,
    password: false,
    status: 0,
}

interface IState extends Authorization.AuthorizationRequest {
    submittingForm: boolean,
    isRegisterModalOpened: boolean,
}

export default class LoginForm extends React.Component<IProps> {

    state: IState = {
        email: '',
        password: '',
        isRegisterModalOpened: false,
        rememberMe: false,
        submittingForm: false,
        error: { ...initError },
    }

    componentDidMount = () => {
        if (window.storageGetItemValue("Auth-memberID") !== null) this.handleRedirect();
    }

    handleChange = () => {

    }

    validatePassword = (psw: string) => {
        if (psw.length >= 8 && psw.length <= 50)
            return true
        return false
    }

    handleSubmit = (event: FormEvent<HTMLElement>, setAuthToken: (token: string | null) => void, setProfileData: (member: MembersTypeRes.IMember) => void) => {
        event.preventDefault();

        let error = { ...initError };
        if (!validateEmail(this.state.email))
            error.email = true;
        if (!this.validatePassword(this.state.password))
            error.password = true;
        if (error.email || error.password) {
            this.setState({ error });
            return;
        }

        this.setState({ submittingForm: true });

        axios.post('Authentication/login', { userName: this.state.email, password: this.state.password, rememberMe: this.state.rememberMe })
            .then(res => {
                let response = res.data;
                if (response.status === "success") {
                    window.storageSetItem("Auth-token", response.data.token);
                    window.storageSetItem("mpa-token", response.data.token);
                    if (response.data.deviceToken)
                        window.storageSetItem("Auth-device-token", response.data.deviceToken)
                    window.storageSetItem("Auth-email", CryptoJS.AES.encrypt(this.state.email, '=$k@fU4P**vJzu!Cvf~ZSp7(;.F}tR\`_ce*(A3^:/dvdVE/[r').toString());
                    window.storageSetItem("Auth-password", CryptoJS.AES.encrypt(this.state.password, '=$k@fU4P**vJzu!Cvf~ZSp7(;.F}tR\`_ce*(A3^:/dvdVE/[r').toString());
                    //retrieve profile info:
                    axios.get('Members/GetMyProfile')
                        .then(res => {
                            this.setState({ submittingForm: false });
                            let profileResponse = res.data;
                            if (profileResponse.status === "success") {
                                window.storageSetItem("Auth-memberID", profileResponse.data.memberID);
                                window.storageSetItem("Auth-memberTypeID", profileResponse.data.memberTypeID);
                                window.storageSetItem("Auth-roles", profileResponse.data.memberRoles);
                                // if first login in case user was pre-registered or imported
                                window.storageSetItem("isFirstAccess", profileResponse.data.isFirstAccess ? 'S' : 'N');
                            }
                            setAuthToken(response.data.token);
                            setProfileData(profileResponse.data);
                            if (this.props.type === "modal" && this.props.openModal)
                                this.props.openModal(false);

                            if (this.props.fnOnClose) {
                                //history.push('/')
                                this.props.fnOnClose(true)
                            }
                            else {
                                if (history.location.pathname === '/' || history.location.pathname === '/login') {
                                    history.push("/home-page")
                                }
                            }
                        })
                        .catch(e => {
                            this.setState({ submittingForm: false });
                        })

                } else {
                    this.setState({ authorized: false, submittingForm: false });
                    setAuthToken(null);
                    if (this.props.type === "modal" && this.props.openModal)
                        this.props.openModal(false);
                }
            }).catch(err => {
                this.setState({
                    error: { email: false, password: false, status: err.response ? err.response.status : 404 },
                    submittingForm: false
                });
            });
    }

    openRegisterModal = (value: boolean) => {
        this.setState({ isRegisterModalOpened: value })
    }

    handleRedirect = () => {
        return history.push("/")
    }

    render() {

        const { type, isModalOpened = false, openModal = () => { } } = this.props;
        const { error, submittingForm, isRegisterModalOpened } = this.state;
        const { location } = this.props;

        const content = (
            <>
                <div className="input-group mb-3">
                    <input
                        type="email" name="email" placeholder="email"
                        className="form-control u-font-size-10" aria-label="Email" aria-describedby="basic-addon1"
                        value={this.state.email}
                        onChange={(e) => { this.setState({ email: e.target.value.toLowerCase() }) }}
                    />
                    <div className="input-group-append">
                        <span className="input-group-text u-font-size-10" id="basic-addon1">Email</span>
                    </div>
                    <div className={`invalid-feedback ${error.email ? "d-block" : ""}`}>You have entered an invalid email address</div>
                </div>
                <div className="input-group mb-3">
                    <input
                        type="password" name="password" placeholder="password"
                        className="form-control u-font-size-10" aria-label="Password" aria-describedby="basic-addon2"
                        value={this.state.password}
                        onChange={(e) => { this.setState({ password: e.target.value }) }}
                    />
                    <div className="input-group-append">
                        <span className="input-group-text u-font-size-10" id="basic-addon2">Password</span>
                    </div>
                    <div className={`invalid-feedback ${error.password ? "d-block" : ""}`}>You have entered an invalid password</div>
                </div>
                <div className="form-check mb-3">
                    <label className="form-check-label" htmlFor="rememberMe">
                        <input type="checkbox" className="form-check-input" id="rememberMe"
                            onChange={(e) => { this.setState({ rememberMe: e.target.checked }) }}
                        />
                        Remember me
                    </label>
                </div>
                <p><Link onClick={() => { openModal(false) }} to="/forgot-password">Forgot password?</Link></p>
                <div className={`invalid-feedback mb-3 ${error.status === 401 ? "d-block" : ""}`}>Wrong Email or password! If you are not registered yet please click the REGISTER button.</div>
                <div className={`invalid-feedback mb-3 ${error.status === 404 ? "d-block" : ""}`}>Network error. Please check your internet connection and try again.</div>
                <div className={`invalid-feedback mb-3 ${error.status !== 0 && error.status !== 401 && error.status !== 404 ? "d-block" : ""}`}>An error has occurred.</div>
            </>
        )

        return (
            <>
                {isRegisterModalOpened ? <Registration type="modal" isModalOpened={isRegisterModalOpened} openModal={this.openRegisterModal} /> : null}

                <AppContextConsumer>
                    {
                        appContext => appContext && (
                            appContext.authToken !== null ?
                                null
                                : (
                                    type === "modal" ? (
                                        <div onKeyPress={(e: KeyboardEvent<HTMLDivElement>) => { if(e.key === 'Enter') this.handleSubmit(e, appContext.setAuthToken, appContext.setProfileData) }}>
                                            <Modal show={isModalOpened} onHide={() => { openModal(false) }} size="lg" className="login u-font-size-10">
                                                <Modal.Header closeButton>
                                                    <Modal.Title>Log in</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    {content}
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    <Button variant="light" onClick={() => { openModal(false) }}>
                                                        Close
                                                    </Button>
                                                    <Button
                                                        variant="primary"
                                                        disabled={submittingForm}
                                                        onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => { this.handleSubmit(e, appContext.setAuthToken, appContext.setProfileData) }}>
                                                        Login
                                                    </Button>
                                                </Modal.Footer>
                                            </Modal>
                                        </div>
                                    ) :
                                        (
                                            <div onKeyPress={(e: KeyboardEvent<HTMLDivElement>) => { if(e.key === 'Enter') this.handleSubmit(e, appContext.setAuthToken, appContext.setProfileData) }}>
                                                <div className="login u-font-size-10">
                                                    {content}
                                                    <div className="login-buttons">
                                                        <Button
                                                            variant="primary"
                                                            disabled={submittingForm}
                                                            onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => { this.handleSubmit(e, appContext.setAuthToken, appContext.setProfileData) }}
                                                        >
                                                            Login
                                                        </Button>
                                                        <Button
                                                            variant="primary"
                                                            disabled={submittingForm}
                                                            onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => { this.openRegisterModal(true) }}>
                                                            Register
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                )
                        )
                    }
                </AppContextConsumer>
            </>
        );
    }
}
