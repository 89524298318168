require('isomorphic-fetch');

// Use forEach with async functions to wait 
export async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

// Scroll top to element with specific id
export function scrollToElementWithId(id, yOffset = 0) {
  const element = document.getElementById(id);
  if (element) {
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: 'smooth' });
  }
}

// Detects if mobile device is iOS 
export const isIos = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /iphone|ipad|ipod/.test(userAgent);
}

// Detects if mobile device
export const isMobileDevice = () => {
  return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
};

// Detects if device is in standalone mode (=> PWA installed)
export const isInStandaloneMode = () => (window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone === true);

// Detects if browser is IE
export const isIE = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return userAgent.indexOf("msie ") > -1 || userAgent.indexOf("trident/") > -1;
}

// Check if email is valid
export const validateEmail = (email) => {
  if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email))
    return true;
  return false;
}

// AddDays to a specific date
export const addDays = (date, numDaysToAdd) => {
  var finalDate = new Date();
  finalDate = finalDate.setDate(date.getDate() + numDaysToAdd);
  return new Date(finalDate);
}

// RemoveDays from a specific date
export const removeDays = (date, numDaysToRemove) => {
  var finalDate = new Date();
  finalDate = finalDate.setDate(date.getDate() - numDaysToRemove);
  return new Date(finalDate);
}

// Remove HTML tags from a string
export const cleanText = (text) => {
  text = text.replace(/<br\s*[\/]?>/g, " ");
  return text.replace(/<\/?[^>]+(>|$)/g, "").replace(/&\/?[^]+(;)/g, "");
}

export const truncateText = (text, maxChars) => {
  let formattedText = text;
  if (text.length >= maxChars) {
    formattedText = text.substr(0, maxChars - 5).concat('...')
  }
  return formattedText;
}

// Convert from hex to rgb
export function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
}


// Check Network Connection
export const checkNetworkAvailable = () => {
  return new Promise((resolve, reject) => {
    fetch('fetchMe.txt?v=' + new Date().getTime())
      .then(res => resolve("OK"))
      .catch(err => reject("KO"))
  });
};


// Get window size: can be big, medium or small
export const getWindowSize = () => {
  let size = 'big';
  if (window.matchMedia('(max-width: 1024px)').matches) size = 'medium';
  if (window.matchMedia('(max-width: 600px)').matches) size = 'small';
  return size;
}


// IE 11 fix: truncate text manually (prop -webkit-line-clamp not available)
export const ellipsizeText = (text, maxChars) => {
  let formattedText = text;
  if (document.body.style["webkitLineClamp"] === undefined) {
    if (text.length >= maxChars) {
      formattedText = text.substr(0, maxChars - 5).concat('...')
    }
  }
  return formattedText;
}

export const setIdDescription = (idDescription) => {
  let finalText = idDescription.replace(/ & /, '-');
  // finalText = parseHTML(finalText);
  finalText = finalText.replace(/ /g, '-');
  finalText = finalText.toLocaleLowerCase();
  finalText = 'box-' + finalText;
  finalText = finalText.replace('<span-style="text-transform:-lowercase;">', '')
  finalText = finalText.replace('</span>', '')
  return finalText;
}

export const urlify = (text) => {
  var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
  //var urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(urlRegex, function (url, b, c) {
    var url2 = (c == 'www.') ? 'http://' + url : url;
    return '<a class="zbi-custom-link" href="' + url2 + '" target="_blank">' + url + '</a>';
  })
}
// CREATE AND DOWNLOAD CSV FILE

// Create file from data and download it
// * data: json
// * filename: name.ext
// * type: type of the file to download
export function downloadDataIntoCsv(data, filename, type) {
  var fileData = convertJsonToCsv(data);
  var file = new Blob(["\ufeff" + fileData], { type: type });
  if (window.navigator.msSaveOrOpenBlob) // IE10+
    window.navigator.msSaveOrOpenBlob(file, filename);
  else { // Others
    var a = document.createElement("a"),
      url = URL.createObjectURL(file);
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    setTimeout(function () {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 0);
  }
}

// Receive a json and returns a string in csv format
function convertJsonToCsv(json) {
  const JOINCHAR = ';';
  var fields = Object.keys(json[0])
  var replacer = function (key, value) { return value === null ? '' : value }
  var csv = json.map(function (row) {
    return fields.map(function (fieldName) {
      return JSON.stringify(row[fieldName], replacer)
    }).join(JOINCHAR)
  })
  csv.unshift(fields.join(JOINCHAR)) // add header column
  return (csv.join('\r\n'));
}

// Force portrait or landscape when printing
export function forcePagePrintDirection(size) {
  var css = '@page { size: ' + size + ' }',
    head = document.head || document.getElementsByTagName('head')[0],
    style = document.createElement('style');

  style.type = 'text/css';
  style.media = 'print';

  if (style.styleSheet) {
    style.styleSheet.cssText = css;
  } else {
    style.appendChild(document.createTextNode(css));
  }
  head.appendChild(style);
}



/* COOKIES */

// Set cookie
export function setCookie(name, value, hours) {
  var expires = "";
  if (hours) {
    var date = new Date();
    date.setTime(date.getTime() + (hours * 60 * 60 * 1000));
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

// Get cookie
export function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

// Remove cookie
export function removeCookie(name) {
  document.cookie = name + '=; Max-Age=-99999999;';
}


// Draggable element
export function dragElement(elmnt) {
  var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
  if (document.getElementById(elmnt.id + "header")) {
    /* if present, the header is where you move the DIV from:*/
    document.getElementById(elmnt.id + "header").onmousedown = dragMouseDown;
  } else {
    /* otherwise, move the DIV from anywhere inside the DIV:*/
    elmnt.onmousedown = dragMouseDown;
  }

  function dragMouseDown(e) {
    e = e || window.event;
    e.preventDefault();
    // get the mouse cursor position at startup:
    pos3 = e.clientX;
    pos4 = e.clientY;
    document.onmouseup = closeDragElement;
    // call a function whenever the cursor moves:
    document.onmousemove = elementDrag;
  }

  function elementDrag(e) {
    e = e || window.event;
    e.preventDefault();
    // calculate the new cursor position:
    pos1 = pos3 - e.clientX;
    pos2 = pos4 - e.clientY;
    pos3 = e.clientX;
    pos4 = e.clientY;
    // set the element's new position:
    elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
    elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
  }

  function closeDragElement() {
    /* stop moving when mouse button is released:*/
    document.onmouseup = null;
    document.onmousemove = null;
  }
}

export const isUserIranian = (userData) => {
  if (userData && userData.memberTypeID === 1 &&
    userData.anagraphics && userData.anagraphics.length > 0 &&
    userData.anagraphics[0] && userData.anagraphics[0].country &&
    userData.anagraphics[0].country.countryISOCode2 === "IR") {
    return true;
  }
  return false;
}

export const isUserEnabledForFacultyRoom = (memberTypeID) => {
  if (window.userInRole("FacultyRoomViewer") || memberTypeID === 2)
    return true;
  return false;
}

/* FILES CONVERSION */

// Convert file image to base 64 string. return a promise with the value
export function fileToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (reader.result) {
        // var encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
        // if ((encoded.length % 4) > 0) {
        //   encoded += '='.repeat(4 - (encoded.length % 4));
        // }
        resolve(reader.result);
      } else {
        reject('');
      }
    };
    reader.onerror = error => reject('');
  });
}
// Convert a base64 image to file
export const dataURLtoFile = (dataurl, filename) => {

  var arr = dataurl.split(','),
    //@ts-ignore
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  console.log(filename, mime);
  var file = new File([u8arr], filename, { type: mime });

  return file;
}

export const toDate = (date) => {
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date;
}