import React, { useEffect, useState } from "react";
import { ContentsTypeRes } from "src/Models/ResponseModels/Contents";
import loadPage from "../_HOC/loadPage";
import RapidRecoveryEpisodesHeader from "./RapidRecoveryEpisodesHeader";
import RapidRecoveryNextEpisode from "./RapidRecoveryNextEpisode";
import history from 'src/history';
import useCurrentWidth from "../_hooks/useCurrentWidth";
import axiosInstance from "src/config/axios";
import RapidRecoveryPlayerVideo from "./RapidRecoveryPlayerVideo";
import RapidRecoveryEpisode from "./RapidRecoveryEpisode";
import Button from "../_UI/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faPlay, faPlayCircle } from "@fortawesome/free-solid-svg-icons";
import { scrollToElementWithId } from "src/config/functions";


interface IProps {
    response: any,
}

const RapidRecovery = ({ response }: IProps) => {
    const [contents, setContents] = useState<ContentsTypeRes.IContent[]>([]);
    const [contentsTeaser, setContentsTeaser] = useState<ContentsTypeRes.IContent[]>([]);
    const [selectedContents, setSelectedContents] = useState<ContentsTypeRes.IContent[]>([]);
    const [showPlayer, setShowPlayer] = useState<boolean[]>([false, false]);
    const [backgroundImage, setBackgroungImage] = useState('/assets/images/rapid-recovery-series/background1920.png')
    const width = useCurrentWidth();

    useEffect(() => {
        if (response) {
            let contents: ContentsTypeRes.IContent[] = response.data.data.collection;

            let _contentsTeaser = contents.filter(x => x.additionalField04 === "Teaser");
            setContentsTeaser(_contentsTeaser);

            let _contents = contents.filter(x => x.additionalField04 !== "Teaser" && x.additionalField05 !== "RRS_DUMMY");
            setContents(_contents);

            // Save log for current page
            let dummy = contents.filter(x => x.additionalField05 === "RRS_DUMMY");
            if (dummy && dummy.length > 0) {
                axiosInstance.get('Contents/' + dummy[0].contentID + '/SaveLog');
            }

            let queryStringSanitized = window.location.search
            queryStringSanitized = queryStringSanitized.replace('?', '')
            if (queryStringSanitized) {
                let contentFound = false;
                _contents.map((content) => {
                    if (content.friendlyURL === queryStringSanitized) {
                        setSelectedContents([content]);
                        contentFound = true;
                        // Save log for preselected webinar
                        axiosInstance.get('Contents/' + content.contentID + '/SaveLog');
                    }
                })
                if (!contentFound) {
                    setSelectedContents(_contentsTeaser);
                    history.push({
                        pathname: '/rapid-recovery-series',
                        search: ''
                    })
                }
            } else {
                setSelectedContents(_contentsTeaser);
            }
        }
    }, [response])

    useEffect(() => {
        if (selectedContents) {
            setShowPlayer(selectedContents.map(x => false));
        }
    }, [selectedContents]);

    const handleChangeTab = (content: ContentsTypeRes.IContent) => {
        setSelectedContents([content]);
        scrollToElementWithId("player-video", -200);
        history.push({
            pathname: '/rapid-recovery-series',
            search: '?' + content.friendlyURL
        })
        axiosInstance.get('Contents/' + content.contentID + '/SaveLog');
    }

    const resetTeaser = () => {
        setSelectedContents(contentsTeaser);
        scrollToElementWithId("player-video", -200);
        history.push({
            pathname: '/rapid-recovery-series',
            search: ''
        })
    }

    return (
        <section className="rapid-recovery">
            <div className="rapid-recovery-background">
                <div className={"rapid-recovery-background-upper " + (selectedContents.length === 1 ? "episodes" : "")}>
                    <div className="rapid-recovery-header">
                        <img className="rapid-recovery-logo" src="/assets/images/rapid-recovery-series/logo.png" />
                        {/* <div className="rapid-recovery-next-episode ml-auto mt-3 d-flex justify-content-center flex-column">
                            <RapidRecoveryNextEpisode />
                        </div> */}
                        <div className="rapid-recovery-header-buttons-wrapper">
                            <div className="rapid-recovery-header-buttons">
                                {/* <Button
                                    withClass={["small"]}
                                    classes={"rapid-recovery-teaser__button ml-auto mt-3"}
                                    clicked={() => { window.open('/assets/docs/pages/rapid-recovery-prorgam-whitepaper-2021.pdf') }}>
                                    <FontAwesomeIcon className="mr-2" icon={faFile} size="lg" />
                                    Rapid Recovery Program
                                </Button> */}
                                {
                                    !selectedContents.some(x => x.additionalField04 === "Teaser") &&
                                    <Button
                                        withClass={["small"]}
                                        classes={"rapid-recovery-teaser__button ml-auto mt-3"}
                                        clicked={() => resetTeaser()}
                                    >
                                        <FontAwesomeIcon className="mr-2" icon={faPlayCircle} size="lg" />
                                        Watch the Teaser
                                    </Button>
                                }
                            </div>
                        </div>
                    </div>
                    <div id="mt-5 player-video">
                        {
                            selectedContents.length > 1 ?
                                <div className="row teaser-row">
                                    {
                                        selectedContents.map((x, index) => {
                                            return <div className="col-lg-6 col-sm-12 teaser-col">
                                                {
                                                    showPlayer[index] ?
                                                        <RapidRecoveryPlayerVideo
                                                            showShareLink={false}
                                                            key={x.contentID}
                                                            contentsList={[x]}
                                                        />
                                                        :
                                                        <div className="container">
                                                            <div className="thumbnail-wrapper"
                                                                style={{ marginLeft: index === 0 ? "auto" : "unset" }}
                                                                onClick={() => {
                                                                    axiosInstance.get('Contents/' + x.contentID + '/SaveLog');
                                                                    setShowPlayer(prev => {
                                                                        let upd = [...prev];
                                                                        upd[index] = true;
                                                                        return upd;
                                                                    });
                                                                }}>
                                                                <h3 className="rapid-recovery-video-title u-font-size-18 mb-3 text-center">{x.contentTitle}</h3>
                                                                <div className="thumbnail" >
                                                                    <img className="img-fluid" src="/assets/images/rapid-recovery-series/teaser.jpg" />
                                                                    <div className="thumbnail-play">
                                                                        <FontAwesomeIcon icon={faPlay} color="white" size="2x" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                }
                                            </div>
                                        })
                                    }
                                </div>
                                :
                                <RapidRecoveryPlayerVideo
                                    contentsList={selectedContents}
                                />
                        }
                    </div>
                </div>
                <div className="rapid-recovery-background__gradient">

                    <RapidRecoveryEpisodesHeader />

                    <div className="rapid-recovery-episode row justify-content-center">
                        {
                            contents.map((content) => {
                                if (content.contentTitle !== 'Teaser') {
                                    return (
                                        <div className="col-xl-3 col-lg-6 col-sm-12" key={content.contentID} onClick={(e) => handleChangeTab(content)} >
                                            <RapidRecoveryEpisode
                                                ID={content.contentID}
                                                title={content.contentTitle}
                                                subtitle={content.contentSubTitle}
                                                image={content.imageURL}
                                                iframe={content.contentDescription}
                                                firstFaculty={content.additionalField02}
                                                secondFaculty={content.additionalField03}
                                            />
                                        </div>
                                    )
                                }
                            })

                        }
                    </div>
                </div>
            </div>
        </section >
    )
}

export default loadPage(
    RapidRecovery,
    {
        url: "Contents/Categories/rapid-recovery-series/Contents",
        method: "GET",
        data: {}
    }
);