import React, { useState, useEffect, forwardRef, useRef, useImperativeHandle } from 'react';
// @ts-ignore
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import Carousel from 'nuka-carousel';
import { faChevronRight, faChevronLeft} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import history from '../../../history';
import { homeCarouselImages } from '../../../config/category-files';

type Timages = { 
    ID: number;
    src: string;
    alt: string;
    link: string;
    // srcBig: string; 
    // srcMedium: string; 
    // srcSmall: string
}[];

const HomePageCarousel = forwardRef((props, ref) => {

  const [images, setImages] = useState<Timages>([]);

  useImperativeHandle(ref, () => ({
    loadImages: loadImages
  }));

  // Load placeholder image while waiting for effective image to be loaded
  function loadImages(width: number) {
    return new Promise((resolve, reject) => {
      loadEffectiveImages(width, resolve);
    });
  };

  async function loadEffectiveImages(width: number, resolve: (value: string) => void){
    var images: Timages = [];
    for(let i = 0; i < homeCarouselImages.length; i++){
      var image = homeCarouselImages[i];
      let src = '';
      if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        src = await window.idbCustom.loadImage(`${process.env.PUBLIC_URL}/${image.folder}/${image.fileNameMedium}.${image.fileExt}`)
      } else {
        src = await window.idbCustom.loadImage(`${process.env.PUBLIC_URL}/${image.folder}/${image.fileNameBig}.${image.fileExt}`)
      }
      images.push({ ID: image.ID, src: src, alt: image.alt, link: image.link })
    }
    setImages(images);
    setTimeout(() => resolve("done!"), 200);
  }
  

  // async function loadImages(width: number) {
  //   var images: Timages = [];
  //   for(let i = 0; i < homeCarouselImages.length; i++){
  //     var image = homeCarouselImages[i];
  //     let src = '';
  //     if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
  //       src = await window.idbCustom.loadImage(`${process.env.PUBLIC_URL}/${image.folder}/${image.fileNameMedium}.${image.fileExt}`)
  //     } else {
  //       src = await window.idbCustom.loadImage(`${process.env.PUBLIC_URL}/${image.folder}/${image.fileNameBig}.${image.fileExt}`)
  //     }
  //     images.push({ ID: image.ID, src: src, alt: image.alt, link: image.link })
  //   }
  //   setImages(images);
  //   return true;
  // };

    const carouselItems = images.map(image => {
      return (
        <img
          key={image.ID}
          className="d-block w-100"
          src={image.src}
          alt={image.alt}
          onClick={() => {
            if(image.alt === 'fracture'){
              window.location.href = image.link;
            } 
            else {
              history.push(image.link) 
            }
            /* else if (image.alt === 'What Should We Do?') {
              window.open(image.link);
            } */
          }}
        />
      )
    })

    const renderDotControls = ({
        currentSlide,
        slideCount,
        goToSlide,
    }: { slideCount: number, currentSlide: number, goToSlide: any }) => {
        return (
          <div
            className="slider-control-bottomcenter"
            style={{
              position: 'absolute',
              bottom: '10px',
              left: '50%',
              transform: 'translateX(-50%)'
            }}
          >
            <ul
              style={{
                position: 'relative',
                margin: '0px',
                padding: '0px',
                display: 'flex'
              }}
            >
              {slideCount > 1 ? [...Array(slideCount)].map((sc, i) => (
                <li
                  style={{ listStyleType: 'none', display: 'inline-block' }}
                  key={i + 1}
                  onClick={() => goToSlide(i)}
                >
                  <div
                    style={{
                      border: '1px #fff solid',
                      background: currentSlide === i ? '#fff' : 'transparent',
                      borderRadius: '50%',
                      margin: '0 3px'
                    }}
                  ></div>
                </li>
              )) : null}
            </ul>
          </div>
        );
    };

    return (
      carouselItems.length > 0 ?
      <TransitionGroup>
        <CSSTransition key={images.length} timeout={1000} classNames="alert">
          <Carousel 
            renderCenterLeftControls={({ previousSlide }) => (
                <button onClick={previousSlide}>
                    <FontAwesomeIcon icon={faChevronLeft} />
                </button>
            )}
            renderCenterRightControls={({ nextSlide }) => (
                <button onClick={nextSlide}>
                    <FontAwesomeIcon icon={faChevronRight} />
                </button>
            )}
            renderBottomCenterControls={props => renderDotControls(props)}
            autoplay={true}
            wrapAround={false}
            speed={1000}
            transitionMode="scroll"
            slidesToShow={1}
            slidesToScroll={1}
          >
            {carouselItems}
          </Carousel>
        </CSSTransition>
      </TransitionGroup> 
      :
      <div className="col-12 p-0 banner-loading-wrapper">
        <div id="banner-loader" className="spinner-border color-blue banner-loader" role="status">
          <span className="sr-only">Loading...</span>
        </div>
        <img src="assets/images/carousel/banner-empty.jpg" className="img-fluid" />
      </div>
    );
    
})

export default HomePageCarousel;