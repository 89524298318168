import axios from 'axios';

const isIOS = !!navigator.userAgent && /iPad|iPhone|iPod/.test(navigator.userAgent)

const getAppVersion = () => {
  const url = `version.txt?c=${Date.now()}`
  return axios.get(url);
}

const handleRefreshForUpdate = () => {
    if (isIOS) {
      const currentVersion = window.storageGetItemValue('appVersion');

      getAppVersion()
      .then((response) => {
        const data = response.data;
        if (data !== currentVersion) {
          window.storageSetItem('appVersion', data)
          window.location.reload()
        }
      })
    }
}

export default handleRefreshForUpdate;