import React, { useState, useEffect, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as faStarFull } from '@fortawesome/free-solid-svg-icons';
import { faStar as faStarEmpty } from '@fortawesome/free-regular-svg-icons';
import styled from 'styled-components';

import { ctxtApp } from "../../../context";
import { hexToRgb, setIdDescription } from '../../../config/functions';
import { TagsTypeRes } from 'src/Models/ResponseModels/Tags';
import axiosInstance from 'src/config/axios';
import history from 'src/history';
import { MembersTypeRes } from 'src/Models/ResponseModels/Members';
import { replace } from 'formik';


// INFO:
// TagParentID
// ImageResourceID: picture to load
// Color: hexadecimal
// Visible

interface IProps {
    tag: TagsTypeRes.ITag
}

const ItemHeader = styled.div`
    background: linear-gradient(-45deg, 
        rgba(${(props: any) => props.color},.2), 
        rgba(${(props: any) => props.color},.6), 
        rgba(${(props: any) => props.color},.2), 
        rgba(${(props: any) => props.color},.6));
    background-size: 400% 400%;
    ${(props: any) => props.pictureLoaded ? 'padding-top: 0' : ''}
`;

const Item = styled.div`
    &::after {
        border-right-color: ${(props: any) => props.color};
        border-top-color: ${(props: any) => props.color};
    }
`;

const ItemNameWrapper = styled.div`
    background-color: ${(props: any) => props.color};
`;


const InterestsItem = ({ tag }: IProps) => {

    const context = useContext(ctxtApp);
    const userData = context && context.userData as MembersTypeRes.IMember;

    const [isFavorite, setFavorite] = useState<boolean>(false);
    const [picture, setPicture] = useState<string>('');
    const [apiload, setApiload] = useState<boolean>(false);

    const favIcon = isFavorite ? faStarFull : faStarEmpty;
    const hexColor = tag.color;
    const rgbColorObj = hexToRgb(hexColor);
    const rgbColor = rgbColorObj ? `${rgbColorObj.r}, ${rgbColorObj.g}, ${rgbColorObj.b}` : '';

    const friendlyUrl = tag.tagSettings && tag.tagSettings.friendlyUrl && tag.tagSettings.friendlyUrl !== '' ? tag.tagSettings.friendlyUrl : tag.tagID;

    useEffect(() => {
        loadTagItems().finally();
    }, [])

    const loadTagItems = async () => {
        if (tag.tagSettings && tag.tagSettings.imageResourceID) {
            axiosInstance.get('Resources/' + tag.tagSettings.imageResourceID + '/base64')
                .then(res => {
                    const response = res.data;
                    if (response.status === 'success') {
                        if (response.data) {
                            setPicture(response.data.content);
                        }
                    }
                })
        }
    }

    useEffect(() => {
        if (userData) {
            setFavorite(userData.favoriteTags.findIndex(fav => fav.tagID === tag.tagID) >= 0);
        }
    }, [userData])

    const toggleFavorite = (e: any) => {
        e.stopPropagation();
        const url = `Members/Tags/${tag.tagID}`;
        const method = isFavorite ? 'DELETE' : 'POST';
        axiosInstance({ method, url })
            .then(res => {
                const detail = {
                    action: isFavorite ? 'del' : 'add',
                    tagID: tag.tagID.toString(),
                    reloadFilters: false,
                }
                if (detail.action === 'del') {
                    if (userData) {
                        var index = userData.favoriteTags.findIndex(f => f.tagID === parseInt(detail.tagID));
                        userData.favoriteTags.splice(index, 1);
                    }
                }
                else { 
                    const newFavorite: MembersTypeRes.IFavoriteTag = {
                        tagID: parseInt(detail.tagID),
                    };
                    if (userData) {
                        userData.favoriteTags.push(newFavorite);
                    }
                }
                window.dispatchEvent(new CustomEvent('updateTagFavorites', { detail: detail }));
                setFavorite(!isFavorite)
                console.log("res", res)
            })
            .catch(err => {
                // Manage error
            })
    }

    const handleInterestClick = () => {
        window.scrollTo(0, 0);
        let categoryUrl = tag.tagID.toString();
        if (tag.tagSettings && tag.tagSettings.friendlyUrl && tag.tagSettings.friendlyUrl !== '')
            categoryUrl = tag.tagSettings.friendlyUrl;
        history.push('/category/' + categoryUrl);
    }

    return (
        <Item color={hexColor} data-gtmname={setIdDescription(tag.tagDescription)} className="interests-item gtm-track-click-home zoom" id={setIdDescription(tag.tagDescription)} onClick={handleInterestClick}>
            <ItemHeader color={rgbColor} pictureLoaded={picture.length > 0} className="interests-item-header">
                {
                    picture &&
                    <img src={picture}
                        className="img-fluid interests-item-picture" alt="Segment" />
                }
                <div className="interests-item-favorite"
                    onClick={toggleFavorite}>
                    <FontAwesomeIcon
                        className="interests-item-favorite-icon"
                        icon={favIcon}
                    />
                </div>
            </ItemHeader>
            <ItemNameWrapper color={hexColor} className="interests-item-name-wrapper">
                <p className="interests-item-name u-font-size-10 mb-0">
                    {tag.tagDescription}
                </p>
            </ItemNameWrapper>
        </Item>
    )
}

export default InterestsItem;