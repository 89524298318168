import React from 'react';
import { TagsTypeRes } from 'src/Models/ResponseModels/Tags';
import CategoriesItem from '../CategoriesItem/CategoriesItem';
interface IProps {
    tags: TagsTypeRes.ITag[]
}
const CategoriesList = ({ tags }: IProps) => {
    return (
        <div className="categories-list">
            <div className="categories-list-row row">
                {
                    tags.map(tag => {
                        return (
                            <div key={tag.tagID} className="categories-list-col col-md-4 col-6">
                                <CategoriesItem tag={tag} />
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}
export default CategoriesList;