import React from 'react';

import Button from '../_UI/Button/Button';
import axios from '../../config/axios';
import { validateEmail } from '../../config/functions';

interface IProps {

}

interface IState {
    email: string,
    error: {
        email: boolean
    },
    APIerror: string,
    completed: boolean,
    sendingEmail: boolean
}


class LoginPage extends React.Component<IProps,IState> {

    state = {
        email: '',
        error: {
            email: false,
        },
        APIerror: '',
        completed: false,
        sendingEmail: false,
    }


    handleButtonClick = () => {
        const { email, error } = this.state;
        if(!validateEmail(email)){
            this.setState({ error: { email: true }, completed: false, APIerror: '' });
        } else {
            // if ok, send email
            this.setState({ error: { email: false }, sendingEmail: true });
            axios.post('Members/SendEmailRetrievePassword', { email: email}).then((res) => {
                const response = res.data;
                if(response.status === 'success'){
                    this.setState({ completed: true, APIerror: '', sendingEmail: false });
                } else {
                    this.setState({ 
                        completed: false, 
                        APIerror: response.message.indexOf('disabled') >= 0 ? 
                        'Forgot the password? Please note that your account is pending approval by a Zimmer Biomet Admin. Once approved, you will receive a confirmation email. In the meantime, you will be unable to access the platform or recover your password. Thank you for your patience and understanding.' :
                        response.message,
                        sendingEmail: false
                    });
                }
            }).catch(e => {
                if(e.response){
                    const response = e.response.data;
                    if(response.message){
                        this.setState({ 
                            completed: false, 
                            APIerror: response.message.indexOf('disabled') >= 0 ? 
                            'Forgot the password? Please note that your account is pending approval by a Zimmer Biomet Admin. Once approved, you will receive a confirmation email. In the meantime, you will be unable to access the platform or recover your password. Thank you for your patience and understanding.' :
                            response.message,
                            sendingEmail: false
                        });
                    } else {
                        this.setState({ completed: false, APIerror: 'An error has occurred', sendingEmail: false });
                    }
                } else {
                    this.setState({ completed: false, APIerror: 'An error has occurred', sendingEmail: false });
                }
            })
        }
    }

    render() {

        const { email, error, completed, APIerror, sendingEmail } = this.state;

        return (
            <div className="forgot-psw">
                <div className="forgot-psw-header text-center">
                    <h3 className="u-font-size-16 mb-0">Forgot your password?</h3>
                </div>
                <div className="forgot-psw-body">
                    <p className="text-center u-font-size-10">
                        Please insert your email, a link to change your password will be sent to your inbox.
                    </p>
                    <div className="col-md-12">
                        <div className="input-group mb-3">
                            <input
                                type="email" name="email" placeholder="email"
                                className="form-control u-font-size-10" aria-label="Email" aria-describedby="basic-addon1"
                                value={email}
                                onChange={(e) => { this.setState({ email: e.target.value.toLowerCase() }) }}
                            />
                            <div className="input-group-append">
                                <span className="input-group-text u-font-size-10" id="basic-addon1">Email</span>
                            </div>
                            <div className={`invalid-feedback ${error.email ? "d-block" : ""}`}>You have entered an invalid email address</div>
                        </div>
                        <Button 
                            withClass={["primary","center"]} 
                            classes="mb-3" 
                            clicked={this.handleButtonClick}
                            disabled={sendingEmail}
                        >Send me the link</Button>
                    </div>
                </div>
                <div className={`text-center mb-3 px-3 valid-feedback ${completed ? "d-block" : ""}`}>An email has been sent to {email}</div>
                <div className={`text-center mb-3 px-3 invalid-feedback ${APIerror ? "d-block" : ""}`}>{APIerror}</div>
            </div>
        );
    }
    
}

export default LoginPage;