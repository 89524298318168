import axios from '../../config/axios';
import MemberLog from '../../Models/Log/MemberLog';


class Logger {
    saveLog = (data:MemberLog, async:boolean = true) => {
        if(async)
        {
            console.log("Sending log with AXIOS");
            axios.post('Logs/SaveLog', data).then(res => console.log);
        }
        else
        {
            const token = window.storageGetItemValue("Auth-token");
            var xhr = new XMLHttpRequest();
            xhr.open('POST', window.apiURL + 'Logs/SaveLog', false);
            xhr.setRequestHeader("Content-type", "application/json")
            xhr.setRequestHeader("Authorization", token);
	        xhr.send(JSON.stringify(data));
        }
    }
}

export default Logger;