import React, { useEffect, useState } from 'react';
import axiosModule from 'axios';
import { checkNetworkAvailable } from '../../../config/functions';

interface IProps {
    children: any,
    className?: string
}

const VideoHandler = ({ children, className = "" }:IProps) => {

    let fnTimeout: any;
    let fnInterval: any;

    const [image, setImage] =  useState<string>('');
    const [online, setOnline] =  useState<boolean>(true);

    useEffect(() => {
        
        window.idbCustom.loadImage('assets/images/video-not-found.png').then((res:string) => {
            setImage(res);
        })

        fnInterval = setInterval(() => checkNetworkAvailable().then(res => {
            setOnline(true);
        }).catch(e => {
            setOnline(false);
        }), 5000)


        return () => {
            clearInterval(fnInterval);
        }

    }, [])

    return (
        <>
            { 
                online ? 
                children : 
                <div className={`row ${className}`} style={{ margin: "0 auto" }}>
                    <div className="col-12 text-center">
                        <img src={image} alt="Video not available" className="img-fluid" />
                    </div>
                </div>
            }
        </>
    )

}

export default VideoHandler;