import React from "react";

interface IProps {
    ID: number,
    title: string,
    subtitle: string | null,
    image: string | null,
    iframe: string | null,
    firstFaculty?: string | null,
    secondFaculty?: string | null,
}

const RapidRecoveryEpisode = ({ title, subtitle, image, firstFaculty, secondFaculty }: IProps) => {
    return (
        <div className="rapid-recovery-episode__wrapper">
            {image && <img className="rapid-recovery-episode__img img-fluid" src={image} />}
            {/* <h3 >{subtitle}</h3> */}
            <p className="rapid-recovery-episode__title u-font-size-12" >{title}</p>
            {secondFaculty ?
                <p className="rapid-recovery-episode__facultie  u-font-size-10"> {firstFaculty}, {secondFaculty} </p>
                :
                <p className="rapid-recovery-episode__facultie  u-font-size-10"> {firstFaculty} </p>
            }
        </div>
    )
}

export default RapidRecoveryEpisode;
