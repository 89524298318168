import ReactHtmlParser from 'react-html-parser';

export type TmenuItem = {
  key: string,
  title: any,
  class: string,
  pathname: string,
  state: any,
  cached: boolean,
  isExternal: boolean,
  submenu: TmenuItem[] | null
}

interface MyObject {
  contentCategoryID: number;
  contentCategoryName: string;
  contentCategoryParent: number;
  friendlyURL: string;
}

export const getMenuItems = (events: MyObject[]) => {

  const apiCalls = Object.keys(window.localStorage);
  const menu = [];

  // -- WEBINARS
  menu.push({
    key: 'webinar',
    title: 'WEBINAR',
    class: 'webinar',
    pathname: '/webinars',
    state: { categoryID: 1, categoryName: 'webinar' },
    cached: apiCalls.filter(el => el.includes('Contents/Categories/1')).length > 0,
    submenu: null
  })

  // -- EVENTS
  const eventsSubmenu = events.map(event => ({
    key: event.contentCategoryID,
    title: event.contentCategoryName,
    class: '',
    pathname: `/events/${event.friendlyURL}`,
    state: { categoryID: event.contentCategoryID, categoryName: event.contentCategoryName },
    cached: apiCalls.filter(el => el.includes(`Contents/Categories/${event.friendlyURL}`)).length > 0,
    submenu: null
  }))
  menu.push({
    key: 'events',
    title: 'EVENTS',
    class: 'events',
    pathname: '',
    state: null,
    cached: false,
    submenu: eventsSubmenu
  })

  // -- EDUCATIONAL
  const educationalSubMenu = [
    {
      key: '3d-anatomy',
      title: '3D Anatomy',
      class: '',
      pathname: `/3d-anatomy`,
      roles: ['AnatomyViewer', 'AnatomyWaiting'],
      state: null,
      cached: apiCalls.filter(el => el.includes(`/Contents/Categories/3d-anatomy`)).length > 0,
      submenu: null
    },
    {
      key: 'cmf-and-t',
      title: 'CMF&T Solutions',
      class: '',
      pathname: `/cmf-and-t`,
      state: null,
      cached: apiCalls.filter(el => el.includes(`/Contents/Categories/cmf-and-t`)).length > 0,
      submenu: null
    },
    {
      key: 'on-demand-digital-learning-sessions',
      title: 'On-Demand Digital Learning Sessions',
      class: '',
      pathname: `/on-demand-digital-learning-sessions`,
      state: null,
      cached: apiCalls.filter(el => el.includes(`/Contents/Categories/on-demand-digital-learning-sessions`)).length > 0,
      submenu: null
    },
    {
      key: 'covid19',
      title: 'Covid-19 Series',
      class: '',
      pathname: `/elective-surgery-in-the-covid-19-era`,
      state: null,
      cached: apiCalls.filter(el => el.includes(`/Contents/Categories/elective-surgery-in-the-covid-19-era/Contents`)).length > 0,
      submenu: null
    },
    {
      key: 'robotics',
      title: 'Robotics Series',
      class: '',
      pathname: `/robotics`,
      state: null,
      cached: apiCalls.filter(el => el.includes(`/Contents/Categories/robotics`)).length > 0,
      submenu: null
    },
    {
      key: 'patient-outcomes-in-pkr',
      title: 'Patient outcomes in PKR Series',
      class: '',
      pathname: `/patient-outcomes-in-pkr`,
      state: null,
      cached: apiCalls.filter(el => el.includes(`/Contents/Categories/patient-outcomes-in-pkr`)).length > 0,
      submenu: null
    },
    {
      key: 'pji-series',
      title: 'Periprosthetic Joint Infection Series',
      class: '',
      pathname: `/pji-series`,
      state: null,
      cached: apiCalls.filter(el => el.includes(`Contents/Categories/pji-series`)).length > 0,
      submenu: null
    },
    {
      key: 'zimmer-biomet-for-residents',
      title: 'Zimmer Biomet for Residents',
      class: '',
      pathname: `/zimmer-biomet-for-residents`,
      state: null,
      cached: apiCalls.filter(el => el.includes(`Contents/Categories/zimmer-biomet-for-residents`)).length > 0,
      submenu: null
    },
    {
      key: 'e-learning',
      title: 'E-Learning Modules',
      class: '',
      pathname: `/e-learning`,
      state: null,
      cached: apiCalls.filter(el => el.includes(`Contents/Categories/37`)).length > 0,
      submenu: null
    },
    {
      key: 'modern-cementing-tutorials',
      title: 'MCT Tutorials',
      class: '',
      pathname: `/modern-cementing-tutorials`,
      state: null,
      cached: apiCalls.filter(el => el.includes(`Contents/Categories/modern-cementing-tutorials`)).length > 0,
      submenu: null
    },
    {
      key: 'knee-arthoroplasty-tutorials',
      title: 'Knee Arthroplasty Tutorials',
      class: '',
      pathname: `/knee-arthoroplasty-tutorials`,
      state: null,
      cached: apiCalls.filter(el => el.includes(`Contents/Categories/knee-arthroplasty-tutorials`)).length > 0,
      submenu: null
    },
    {
      key: 'simple-solutions',
      title: 'Simple Solutions',
      class: '',
      pathname: `/simple-solutions`,
      state: null,
      cached: apiCalls.filter(el => el.includes(`Contents/Categories/simple-solutions`)).length > 0,
      submenu: null
    },
    {
      key: 'fracture',
      title: 'Fracture Guide',
      class: '',
      pathname: `https://www.zbinetworkemea.com/fracture/`,
      state: null,
      cached: false,
      submenu: null
    },
    {
      key: 'surgical-technique-using-persona-fuzion',
      title: 'Surgical technique using Persona FuZion',
      class: '',
      pathname: `/surgical-technique-using-persona-fuzion`,
      state: null,
      cached: apiCalls.filter(el => el.includes(`Contents/surgical-technique-using-persona-fuzion`)).length > 0,
      submenu: null
    },
    {
      key: 'surgical-technique-using-nexel-total-elbow',
      title: 'Surgical technique using Nexel<sup>®</sup> Total Elbow',
      class: '',
      pathname: `/surgical-technique-using-nexel-total-elbow`,
      state: null,
      cached: apiCalls.filter(el => el.includes(`/Contents/surgical-technique-using-nexel-total-elbow`)).length > 0,
      submenu: null
    },
    {
      key: 'v-learning',
      title: 'V-Learning',
      class: '',
      pathname: `/v-learning`,
      state: null,
      cached: apiCalls.filter(el => el.includes(`Contents/Categories/v-learning`)).length > 0,
      submenu: null
    },
  ]
  menu.push({
    key: 'educational',
    title: 'EDUCATIONAL',
    class: 'educational',
    pathname: '',
    state: null,
    cached: false,
    submenu: educationalSubMenu
  })


  // -- CLINICAL
  menu.push({
    key: 'clinical-evidence',
    title: 'CLINICAL EVIDENCE',
    class: 'clinical',
    pathname: '/clinical-evidence',
    state: null,
    cached: apiCalls.filter(el => el.includes('Resources/Category/16')).length > 0,
    submenu: null
  })


  // -- EXTRAS
  const extrasSubMenu = [
    {
      key: 'skills-in-pills',
      title: 'Skills in Pills',
      class: '',
      pathname: `/skills-in-pills`,
      state: null,
      cached: apiCalls.filter(el => el.includes(`/Contents/Categories/skills-in-pills`)).length > 0,
      submenu: null
    },
    {
      key: 'e-library',
      title: 'eLibrary',
      class: '',
      pathname: '/e-library',
      state: null,
      cached: apiCalls.filter(el => el.includes('/Contents/e-library')).length > 0,
      submenu: null
    },
    {
      key: 'grants-and-donations',
      title: 'Grants and Donations',
      class: '',
      pathname: `/grants-and-donations`,
      state: null,
      cached: apiCalls.filter(el => el.includes(`/Contents/Categories/grants-and-donations`)).length > 0,
      submenu: null
    },
    {
      key: 'zimmer-tv',
      title: 'Zimmer Biomet TV',
      class: '',
      pathname: 'https://zimmerbiomet.tv',
      state: null,
      cached: false,
      isExternal: true,
      submenu: null
    },
    {
      key: 'industry-links',
      title: 'Industry Links',
      class: '',
      pathname: '/industry-links',
      state: null,
      cached: apiCalls.filter(el => el.includes(`/Contents/Categories/industry-links`)).length > 0,
      submenu: null
    }
  ]
  if (window.storageGetItem("Auth-memberID") != null && window.storageGetItem("Auth-memberTypeID") != null && window.storageGetItem("Auth-memberTypeID").value != "1") {
    extrasSubMenu.push({
      key: 'registrations-contest',
      title: 'ZBIN Contest 2019/20',
      class: '',
      pathname: '/registrations-contest',
      state: null,
      cached: apiCalls.filter(el => el.includes(`/Contents/Categories/registrations-contest`)).length > 0,
      submenu: null
    })
  }
  extrasSubMenu.push({
    key: 'third-party-congresses',
    title: 'Third Party Congresses',
    class: '',
    pathname: '/third-party-congresses',
    state: null,
    cached: apiCalls.filter(el => el.includes(`/Contents/Categories/third-party-congresses`)).length > 0,
    submenu: null
  })
  extrasSubMenu.push({
    key: 'essenti-app',
    title: 'EssentiApp',
    class: '',
    pathname: '/essenti-app',
    state: null,
    cached: apiCalls.filter(el => el.includes(`/Contents/essenti-app`)).length > 0,
    submenu: null
  })
  extrasSubMenu.push({
    key: 'e-book',
    title: 'eBook TKA',
    class: '',
    pathname: '/e-book',
    state: null,
    cached: apiCalls.filter(el => el.includes(`/Contents/Categories/e-book`)).length > 0,
    submenu: null
  })
  extrasSubMenu.push({
    key: 'trabecular-metal-tech',
    title: 'Trabecular Metal<sup>™</sup> Technology',
    class: '',
    pathname: '/trabecular-metal-tech',
    state: null,
    cached: apiCalls.filter(el => el.includes(`/Contents/trabecular-metal-tech`)).length > 0,
    submenu: null
  })
  extrasSubMenu.push({
    key: 'milestones',
    title: 'Milestones',
    class: '',
    pathname: '/milestones/1',
    state: null,
    cached: apiCalls.filter(el => el.includes(`/Contents/Categories/milestones`)).length > 0,
    submenu: null
  })
  menu.push({
    key: 'extras',
    title: 'EXTRAS',
    class: 'extras',
    pathname: '',
    state: null,
    cached: false,
    submenu: extrasSubMenu
  })


  // -- CASE FORUM
  // Devono essere visibili nel menu una settimana prima dell'attivazione
  // Durante il periodo attivo devono avere, alla fine del titolo, Join now
  // Finito il periodo attivo devono sparire dal menu
  const caseforumSubMenu = [];
  const dateNow = new Date();

  // Femoral Revision (May 4th - 16th)
  if (dateNow >= new Date('2020-04-27') && dateNow < new Date('2020-05-17')) {
    caseforumSubMenu.push({
      key: 'case-forum-femoral-revision',
      title: 'Femoral Revision' + (dateNow >= new Date('2020-05-04') ? ' - Join now' : ''),
      class: '',
      pathname: `/case-forum-femoral-revision`,
      state: null,
      cached: apiCalls.filter(el => el.includes(`/Contents/case-forum-femoral-revision`)).length > 0,
      submenu: null
    })
  }
  // How do I decide between UKA and TKA? (May 18th - 30th)
  if (dateNow >= new Date('2020-05-11') && dateNow < new Date('2020-05-31')) {
    caseforumSubMenu.push({
      key: 'case-forum-how-decide-between-uka-tka',
      title: 'How do I decide between UKA and TKA?' + (dateNow >= new Date('2020-05-18') ? ' - Join now' : ''),
      class: '',
      pathname: `/case-forum-how-decide-between-uka-tka`,
      state: null,
      cached: apiCalls.filter(el => el.includes(`/Contents/case-forum-how-decide-between-uka-tka`)).length > 0,
      submenu: null
    })
  }
  // Oxford Cementless PKR (June 1st - 6th)
  if (dateNow >= new Date('2020-05-22') && dateNow < new Date('2020-06-07')) {
    caseforumSubMenu.push({
      key: 'case-forum-oxford-cementless-pkr',
      title: 'Oxford Cementless PKR' + (dateNow >= new Date('2020-06-01') ? ' - Join now' : ''),
      class: '',
      pathname: `/case-forum-oxford-cementless-pkr`,
      state: null,
      cached: apiCalls.filter(el => el.includes(`/Contents/case-forum-oxford-cementless-pkr`)).length > 0,
      submenu: null
    })
  }
  // TM Ankle (June 8th - 20th)
  if (dateNow >= new Date('2020-06-01') && dateNow < new Date('2020-06-21')) {
    caseforumSubMenu.push({
      key: 'case-forum-trabecular-metal-total-ankle',
      title: 'Trabecular Metal<sup>™</sup> Total Ankle' + (dateNow >= new Date('2020-06-08') ? ' - Join now' : ''),
      class: '',
      pathname: `/case-forum-trabecular-metal-total-ankle`,
      state: null,
      cached: apiCalls.filter(el => el.includes(`/Contents/case-forum-trabecular-metal-total-ankle`)).length > 0,
      submenu: null
    })
  }
  // Femoral Revision (May 4th - 16th)
  if (dateNow >= new Date('2020-08-21') && dateNow < new Date('2020-09-13')) {
    caseforumSubMenu.push({
      key: 'case-forum-total-elbow-arthroplasty',
      title: 'Total Elbow Arthroplasty' + (dateNow >= new Date('2020-08-31') ? ' - Join now' : ''),
      class: '',
      pathname: `/case-forum-total-elbow-arthroplasty`,
      state: null,
      cached: apiCalls.filter(el => el.includes(`/Contents/case-forum-total-elbow-arthroplasty`)).length > 0,
      submenu: null
    })
  }
  // Cervical Arthroplasty (September 14th - 26th)
  if (dateNow >= new Date('2020-09-07') && dateNow < new Date('2020-09-27')) {
    caseforumSubMenu.push({
      key: 'case-forum-cervical-arthroplasty',
      title: 'Cervical Arthroplasty' + (dateNow >= new Date('2020-09-14') ? ' - Join now' : ''),
      class: '',
      pathname: `/case-forum-cervical-arthroplasty`,
      state: null,
      cached: apiCalls.filter(el => el.includes(`/Contents/case-forum-cervical-arthroplasty`)).length > 0,
      submenu: null
    })
  }
  // Zimmer Biomet For Residents - Trauma (September 28, 2020 – October, 3 2020)
  if (dateNow >= new Date('2020-09-21') && dateNow < new Date('2020-10-04')) {
    caseforumSubMenu.push({
      key: 'case-forum-zbfr-trauma',
      title: 'ZB4Residents Trauma' + (dateNow >= new Date('2020-09-28') ? ' - Join now' : ''),
      class: '',
      pathname: `/case-forum-zbfr-trauma`,
      state: null,
      cached: apiCalls.filter(el => el.includes(`/Contents/case-forum-zbfr-trauma`)).length > 0,
      submenu: null
    })
  }
  // Complex cases in primary TKA (October 5th - 17th)
  if (dateNow >= new Date('2020-09-28') && dateNow < new Date('2020-10-18')) {
    caseforumSubMenu.push({
      key: 'case-forum-complex-cases-in-primary-tka',
      title: 'Complex cases in primary TKA' + (dateNow >= new Date('2020-10-05') ? ' - Join now' : ''),
      class: '',
      pathname: `/case-forum-complex-cases-in-primary-tka`,
      state: null,
      cached: apiCalls.filter(el => el.includes(`/Contents/case-forum-complex-cases-in-primary-tka`)).length > 0,
      submenu: null
    })
  }
  // Periprosthetic Fractures (October 19, 2020 - October 31, 2020)
  if (dateNow >= new Date('2020-10-12') && dateNow < new Date('2020-11-01')) {
    caseforumSubMenu.push({
      key: 'case-forum-periprosthetic-fractures',
      title: 'Periprosthetic Fractures' + (dateNow >= new Date('2020-10-19') ? ' - Join now' : ''),
      class: '',
      pathname: `/case-forum-periprosthetic-fractures`,
      state: null,
      cached: apiCalls.filter(el => el.includes(`/Contents/case-forum-periprosthetic-fractures`)).length > 0,
      submenu: null
    })
  }
  // Zimmer Biomet For Residents - Shoulder Arthroplasty & Sports Medicine (November 2, 2020 – November 7, 2020)
  if (dateNow >= new Date('2020-10-26') && dateNow < new Date('2020-11-08')) {
    caseforumSubMenu.push({
      key: 'case-forum-zbfr-shoulder-arthroplasty-and-sports-medicine',
      title: 'ZB4Residents Shoulder Arthroplasty & Sports Medicine' + (dateNow >= new Date('2020-11-02') ? ' - Join now' : ''),
      class: '',
      pathname: `/case-forum-zbfr-shoulder-arthroplasty-and-sports-medicine`,
      state: null,
      cached: apiCalls.filter(el => el.includes(`/Contents/case-forum-zbfr-shoulder-arthroplasty-and-sports-medicine`)).length > 0,
      submenu: null
    })
  }
  // Superior Capsular Reconstruction (November 9th - 21st)
  if (dateNow >= new Date('2020-11-02') && dateNow < new Date('2020-11-22')) {
    caseforumSubMenu.push({
      key: 'case-forum-superior-capsular-reconstruction',
      title: 'Superior Capsular Reconstruction' + (dateNow >= new Date('2020-11-09') ? ' - Join now' : ''),
      class: '',
      pathname: `/case-forum-superior-capsular-reconstruction`,
      state: null,
      cached: apiCalls.filter(el => el.includes(`/Contents/case-forum-superior-capsular-reconstruction`)).length > 0,
      submenu: null
    })
  }
  // Zimmer Biomet For Residents - Hip Arthroplasty (November 23, 2020 – November 28, 2020)
  if (dateNow >= new Date('2020-11-16') && dateNow < new Date('2020-11-29')) {
    caseforumSubMenu.push({
      key: 'case-forum-zbfr-hip-arthroplasty',
      title: 'ZB4Residents Hip Arthroplasty' + (dateNow >= new Date('2020-11-23') ? ' - Join now' : ''),
      class: '',
      pathname: `/case-forum-zbfr-hip-arthroplasty`,
      state: null,
      cached: apiCalls.filter(el => el.includes(`/Contents/case-forum-zbfr-hip-arthroplasty`)).length > 0,
      submenu: null
    })
  }
  // Zimmer Biomet For Residents - Knee Arthroplasty (November 30, 2020 – December 5, 2020)
  if (dateNow >= new Date('2020-11-23') && dateNow < new Date('2020-12-06')) {
    caseforumSubMenu.push({
      key: 'case-forum-zbfr-knee-arthroplasty',
      title: 'ZB4Residents Knee Arthroplasty' + (dateNow >= new Date('2020-11-30') ? ' - Join now' : ''),
      class: '',
      pathname: `/case-forum-zbfr-knee-arthroplasty`,
      state: null,
      cached: apiCalls.filter(el => el.includes(`/Contents/case-forum-zbfr-knee-arthroplasty`)).length > 0,
      submenu: null
    })
  }
  // Proximal Humeral Fractures (December 7th - 19th)
  if (dateNow >= new Date('2020-11-30') && dateNow < new Date('2020-12-20')) {
    caseforumSubMenu.push({
      key: 'case-forum-proximal-humeral-fractures',
      title: 'Proximal Humeral Fractures' + (dateNow >= new Date('2020-12-07') ? ' - Join now' : ''),
      class: '',
      pathname: `/case-forum-proximal-humeral-fractures`,
      state: null,
      cached: apiCalls.filter(el => el.includes(`/Contents/case-forum-proximal-humeral-fractures`)).length > 0,
      submenu: null
    })
  }
  // Pre-operative planning for the challenging shoulder arthroplasty (January 18th - 30th)
  if (dateNow >= new Date('2021-01-04') && dateNow < new Date('2021-01-31')) {
    caseforumSubMenu.push({
      key: 'case-forum-pre-operative-planning-for-the-challenging-shoulder-arthroplasty',
      title: 'Pre-operative planning for the challenging shoulder arthroplasty' + (dateNow >= new Date('2021-01-18') ? ' - Join now' : ''),
      class: '',
      pathname: `/case-forum-pre-operative-planning-for-the-challenging-shoulder-arthroplasty`,
      state: null,
      cached: apiCalls.filter(el => el.includes(`/Contents/case-forum-pre-operative-planning-for-the-challenging-shoulder-arthroplasty`)).length > 0,
      submenu: null
    })
  }
  // Rotator cuff injuries and repair options (February 15th - 27th)
  if (dateNow >= new Date('2021-02-01') && dateNow < new Date('2021-02-28')) {
    caseforumSubMenu.push({
      key: 'case-forum-rotator-cuff',
      title: 'Rotator cuff injuries and repair options' + (dateNow >= new Date('2021-02-15') ? ' - Join now' : ''),
      class: '',
      pathname: `/case-forum-rotator-cuff`,
      state: null,
      cached: apiCalls.filter(el => el.includes(`/Contents/case-forum-rotator-cuff`)).length > 0,
      submenu: null
    })
  }
  // How can I simplify this Knee Revision? (March 15th - 27th)
  if (dateNow >= new Date('2021-03-01') && dateNow < new Date('2021-03-28')) {
    caseforumSubMenu.push({
      key: 'case-forum-knee-revision',
      title: 'How can I simplify this knee revision?' + (dateNow >= new Date('2021-03-15') ? ' - Join now' : ''),
      class: '',
      pathname: `/case-forum-knee-revision`,
      state: null,
      cached: apiCalls.filter(el => el.includes(`/Contents/case-forum-knee-revision`)).length > 0,
      submenu: null
    })
  }
  // Interprosthetic and peri-implant fractures (March 29th to April 10th)
  if (dateNow >= new Date('2021-03-16') && dateNow < new Date('2021-04-11')) {
    caseforumSubMenu.push({
      key: 'case-forum-interprosthetic-and-peri-implant-fractures',
      title: 'Interprosthetic and peri-implant fractures' + (dateNow >= new Date('2021-03-29') ? ' - Join now' : ''),
      class: '',
      pathname: `/case-forum-interprosthetic-and-peri-implant-fractures`,
      state: null,
      cached: apiCalls.filter(el => el.includes(`/Contents/case-forum-interprosthetic-and-peri-implant-fractures`)).length > 0,
      submenu: null
    })
  }
  // Personalized TKR Alignment: turning theory into practice (May 17th - 29th)
  if (dateNow >= new Date('2021-05-03') && dateNow < new Date('2021-05-30')) {
    caseforumSubMenu.push({
      key: 'case-forum-partial-knee-ppk',
      title: 'Personalized TKR alignment: turning theory into practice' + (dateNow >= new Date('2021-05-17') ? ' - Join now' : ''),
      class: '',
      pathname: `/case-forum-partial-knee-ppk`,
      state: null,
      cached: apiCalls.filter(el => el.includes(`/Contents/case-forum-partial-knee-ppk`)).length > 0,
      submenu: null
    })
  }
  // Difficult Glenoid (June 14th - 26th)
  if (dateNow >= new Date('2021-05-31') && dateNow < new Date('2021-06-27')) {
    caseforumSubMenu.push({
      key: 'case-forum-difficult-glenoid',
      title: 'Difficult Glenoid' + (dateNow >= new Date('2021-06-14') ? ' - Join now' : ''),
      class: '',
      pathname: `/case-forum-difficult-glenoid`,
      state: null,
      cached: apiCalls.filter(el => el.includes(`/Contents/case-forum-difficult-glenoid`)).length > 0,
      submenu: null
    })
  }
  // Calendar
  if (caseforumSubMenu.length <= 0) {
    caseforumSubMenu.push({
      key: 'case-forum-calendar',
      title: 'Case Forum Calendar',
      class: '',
      pathname: `/case-forum-calendar`,
      state: null,
      cached: false,
      submenu: null
    })
  }

  menu.push({
    key: 'case-forum',
    title: 'CASE FORUM',
    class: 'case-forum',
    pathname: '',
    state: null,
    cached: false,
    submenu: caseforumSubMenu
  })


  // -- VIRTUAL HALL
  menu.push({
    key: 'the-virtual-exhibition-experience',
    title: 'VIRTUAL HALL',
    class: 'virtual-hall',
    pathname: '/the-virtual-exhibition-experience',
    state: null,
    cached: apiCalls.filter(el => el.includes('Contents/the-virtual-exhibition-experience')).length > 0,
    submenu: null
  })

  // -- ROSA KNEE NETWORK
  window.userInRole("RosaKneeViewer") && (
    menu.push({
      key: 'rosa-knee-user-network',
      title: 'ROSA KNEE NETWORK',
      class: 'rosa-knee-network',
      pathname: '/rosa-knee-user-network',
      state: null,
      cached: apiCalls.filter(el => el.includes('Contents/rosa-knee-user-network')).length > 0,
      submenu: null
    })
  )

  // -- ZB Circle access
  window.userInRole("ZBCircleAccess") && (
    menu.push({
      key: 'rosa-knee-user-network',
      title: 'ZBEdge Circle',
      class: 'rosa-knee-network',
      pathname: '/case-forum-zbedge-circle',
      state: null,
      cached: apiCalls.filter(el => el.includes('Contents/case-forum-zbedge-circle')).length > 0,
      submenu: null
    })
  )

  return menu;
} 