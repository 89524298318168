import React from 'react';

import CachedContentsList from './CachedContentsList';
import { Modal } from 'react-bootstrap';
import Button from '../_UI/Button/Button';

type file = {
    path: string,
    subtitle: string,
    title: string,
    category: string,
    order: number,
    link: string,
    type: string,
    size: string
}

interface IState {
    files: file[],
    isModalDeletedFileOpen: boolean,
    showLoader: boolean,
}

interface IProps {
    style?: any,
    close: () => void
}

var interval: any;

class CachedContentsPanel extends React.Component<IProps, IState> {

    state: IState = {
        files: [],
        isModalDeletedFileOpen: false,
        showLoader: false,
    }

    componentDidMount(){
        this.loadCachedFiles();
        interval = setInterval(this.loadCachedFiles, 1000);

        const navbar = document.getElementById("main-nav") as HTMLElement;
        const navbarMobileMenu = document.getElementById("basic-navbar-nav") as HTMLElement;
        const togglerMobile = document.getElementsByClassName("navbar-toggler")[0] as HTMLElement;
        const panel = document.getElementById("panel-cache") as HTMLElement;

        if(navbar && panel){
            if(navbarMobileMenu && togglerMobile && window.getComputedStyle(togglerMobile).display !== "none"){
                panel.style.top = (navbar.offsetHeight - navbarMobileMenu.offsetHeight) + "px"
            } else {
                panel.style.top = (navbar.offsetHeight + 2) + "px"
            }
        }

        window.addEventListener("resize", () => {
            if(navbar && panel){
                if(navbarMobileMenu && togglerMobile && window.getComputedStyle(togglerMobile).display !== "none"){
                    panel.style.top = (navbar.offsetHeight - navbarMobileMenu.offsetHeight) + "px"
                } else {
                    panel.style.top = (navbar.offsetHeight + 2) + "px"
                }
            }
        });

    }

    componentWillUnmount(){
        clearInterval(interval);
    }

    loadCachedFiles = async () => {
        const cachedFiles = window.storageGetItem('indexedDBRef').value;
        let orderedCachedFiles = cachedFiles.sort(this.compare);
        this.setState({
            files: orderedCachedFiles
        })
    }

    handleDeleteFile = (path: string) => {
        this.setState({ showLoader: true });
        window.idbCustom.deleteFile(path).then((res: any) => {
            this.loadCachedFiles();
            this.setState({ isModalDeletedFileOpen: true, showLoader: false });
            // call event on ski pil page to reload available toggle
            window.dispatchEvent(new CustomEvent('updateAvailableOffline'));
        }).catch((e: any) => {
            this.setState({ showLoader: false });
            alert("An error has occurred. Please try again later");
        })
    }

    handleCloseDeletedFileModal = () => {
        this.setState({ isModalDeletedFileOpen: false });
    }

    renderDeletedFileModal = () => {

        return (
            <Modal show={this.state.isModalDeletedFileOpen} onHide={this.handleCloseDeletedFileModal} className="modal-delete" centered>
                <Modal.Header closeButton>
                    <Modal.Title className="u-font-size-12">Storing file</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="u-font-size-9 mb-2">
                        Your file has been removed successfully! It is no longer available offline.
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button withClass={["small","primary"]} clicked={this.handleCloseDeletedFileModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }   

    // order: less number (episode number) means higher priority
    // type: if same order (same episode) show first mp4 then mp3 file
    compare(a: file, b: file) {
        if (a.order > b.order) return 1;
        if (a.order === b.order && a.type === 'mp3') return 1;
        if (a.order < b.order) return -1;
        if (a.order === b.order && a.type === 'mp4') return -1;
        return 0;
    }

    render(){

        const { files, showLoader } = this.state;
        const { style, close } = this.props;
        const totalSize = files.reduce((prev, curr) => prev + (parseFloat(curr['size']) || 0), 0)

        return (
            <>
                { this.renderDeletedFileModal() }

                <div className="panel-cache-overlay modal d-block" onClick={close}></div>
                <div id="panel-cache" className="panel-cache" style={style}>
                    <header className="panel-cache-header">
                        <div className="panel-cache-header__title">
                            <h1 className="u-font-size-11">Offline file manager</h1>
                            <Button withClass={["primary","small"]} clicked={close}>Close</Button>
                        </div> 
                        <h2 className="u-font-size-9">Manage all your saved contents through this panel. You can watch them or delete to free disk space.</h2>
                    </header>
                    <main className="panel-cache-body">
                        <CachedContentsList files={files} deleteFile={this.handleDeleteFile} />
                    </main>
                    <footer className="panel-cache-footer">
                        { showLoader && 
                            <div id="loaderDeleteVideo" className="spinner-border color-blue mr-2 offline__loader" role="status">
                                <span className="sr-only">Loading...</span>
                            </div> 
                        }
                        <p className="u-font-size-10 p-2 mb-0">Total size: {totalSize.toFixed(2)}{" "}MB</p>   
                    </footer>
                </div>
            </>
        )
    }

}

export default CachedContentsPanel;
