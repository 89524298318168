// #IMPORTANT
// Get user and pass saved as glob vars, decrypt and use them to refresh token
// Save API responses in localStorage and, when offline, get them

import axios from 'axios';
import history from '../history';
import ReactGA from 'react-ga';
import { cleanText, isIE } from '../config/functions';

var CryptoJS = require("crypto-js");
const trackingId = "UA-142497713-1";
ReactGA.initialize(trackingId);

// const devURL = 'http://localhost:55159/api/'
// const devURL = 'https://api-test.zbinetworkemea.com/api/'
// const devURL = 'http://apijw.zbinetworkemea.devserver/api/'
// const devURL = 'https://api.zbinetworkemea.com/api/'
// const devURL = 'https://api.zbinetworkemea.com/api/'
//const devMessengerURL = 'https://api-messenger.zbinetworkemea.com/api/'
//const prodURL = 'https://api.zbinetworkemea.com/api/'
//const prodURL = 'https://api.zbinetworkemea.com/api/'
//const prodMessengerURL = 'https://api-messenger.zbinetworkemea.com/api/'
// const prodURL = 'https://api.zbinetworkemea.com/api/'

export const baseURL = process.env.REACT_APP_API_URL || 'http://localhost:55159/api/'
export const messengerURL = process.env.REACT_APP_MESSENGER_URL || 'https://localhost:5001/api'
//export const baseURL = process.env.NODE_ENV === 'development' ? devURL : prodURL;
//export const messengerURL = process.env.NODE_ENV === 'development' ? devMessengerURL : prodMessengerURL;

window.apiURL = baseURL;

const axiosInstance = axios.create({
  baseURL: baseURL
});

const axiosRetryApiCall = axios.create({
  baseURL: baseURL
});

axiosInstance.interceptors.request.use(async (request) => {
  // Do something with request data
  // console.log("request - SUCCESS", request);
  const token = window.storageGetItemValue("Auth-token");
  request.headers.Authorization = token ? token : ''
  if (isIE()) {
    const url = request.url;
    request.url = url + (url.indexOf('?') > 0 ? '&' : '?') + 'id=' + new Date().getTime()
  }
  return request;
  // use an external js class to save useremail and password for refreshing the token
}, function (error) {
  console.log("request - ERROR", error);
  return error;
});

axiosInstance.interceptors.response.use((response) => {
  // Get updated token
  window.lastApiRequest = Date.now();
  var newToken = response.headers["x-token"];
  if (newToken !== undefined) {
    if (newToken.length > 0) {
      window.storageSetItem("Auth-token", newToken);
    }
  }
  // Successful response: Caching response inside localStorage
  if (!response.config.url.match('Authentication') && !response.config.url.match('base64') && !response.config.url.match('Stats')) {
    saveAPIresponse(JSON.parse(JSON.stringify(response)));
  }
  setGoogleAnalyticsContentName(response);
  return response;
  // Error response
}, async (error) => {
  console.log("response - ERROR", error, error.config, error.response);
  // Network error: continue to search the API call in cache
  // Do it only for GET method
  if (!error.response || !error.response.status) {
    if (window.storageGetItem(error.config.url) && error.config.method === "get") {
      return getAPIresponse(error.config.url);
    }
    // Other error (es. Forbidden, Unauthorized): try refreshing token
  } else if (error.response.status === 401 || error.response.status === 403) {
    let token = window.storageGetItemValue("Auth-token");
    const email = window.storageGetItemValue("Auth-email");
    const password = window.storageGetItemValue("Auth-password");
    // If logged
    if (token && email && password) {
      const data = {
        userName: CryptoJS.AES.decrypt(email.toString(), '=$k@fU4P**vJzu!Cvf~ZSp7(;.F}tR\`_ce*(A3^:/dvdVE/[r').toString(CryptoJS.enc.Utf8),
        password: CryptoJS.AES.decrypt(password.toString(), '=$k@fU4P**vJzu!Cvf~ZSp7(;.F}tR\`_ce*(A3^:/dvdVE/[r').toString(CryptoJS.enc.Utf8)
      }
      const config = { headers: { Authorization: token } }
      try {
        // refresh token
        const resToken = await axios.post(baseURL + 'Authentication/SessionRefresh', data, config);
        token = resToken.data.data.token;
        window.storageSetItem("Auth-token", token);
        window.lastApiRequest = Date.now();

        // try again the previous api call
        const resCall = await axiosRetryApiCall({
          method: error.config.method,
          url: error.config.url,
          data: error.config.data ? JSON.parse(error.config.data) : null,
          headers: {
            Authorization: token
          }
        })
        setGoogleAnalyticsContentName(resCall);
        return resCall;
      } catch (error) {

      }
    }
    window.removeStoredUserData();
    // Redirect on login page only if i'm not already there and save the url for redirect
    if (history.location.pathname !== '/login-redirect') {
      window.storageSetItem("redirectTo", window.location.pathname + window.location.search, true);
      console.log("redirectTo home")
      history.push('/');
    }
    return Promise.reject(error);
  }
  console.log("AXIOS RESPONSE ERROR")
  // Do something with response error
  return Promise.reject(error);
});


// Used when I try again the previous call with a new token (status: Forbidden, Unauthorized)
axiosRetryApiCall.interceptors.response.use((response) => {
  if (!response.config.url.match('Authentication') && !response.config.url.match('base64') && !response.config.url.match('Stats')) {
    saveAPIresponse(JSON.parse(JSON.stringify(response)));
  }
  setGoogleAnalyticsContentName(response);
  return response;
}, (error) => {
  if (error.response && (error.response.status === 401 || error.response.status === 403)) {
    window.removeStoredUserData();
    // Redirect on login page only if i'm not already there
    if (history.location.pathname !== '/login-redirect') {
      window.storageSetItem("redirectTo", window.location.pathname + window.location.search, true);
      console.log("login-token-expired")
      history.push('/login-token-expired');
    }
  }
  return Promise.reject(error);
})


const saveAPIresponse = async (response) => {
  const data = response.data.data;
  // Save image background
  if (data && data.contentBody) {
    await window.asyncForEach(data.contentBody, async (body) => {
      // NB: Due to storage chars limit (max 5.200.000) i remove base64 content from response and save it inside indexedDB
      if (body.backgroundImage && body.backgroundImage.content.length > 0) {
        const path = body.contentID + '/contentBody/' + body.contentBlockID + '/backgroundImage';
        const base64 = body.backgroundImage.content;
        const value = await window.idbCustom.saveBase64Image(path, base64);
        body.backgroundImage.content = value;
      }
    })
  }
  // Save preview image from list of contents
  if (data && data.collection) {
    await window.asyncForEach(data.collection, async (content) => {
      if (content.previewImage && content.previewImage.content && content.previewImage.content.length > 0) {
        const path = content.contentID + '/previewImage/content';
        const base64 = content.previewImage.content;
        const value = await window.idbCustom.saveBase64Image(path, base64);
        content.previewImage.content = value;
      }
    })
  }
  // NB: Due to storage chars limit (max 5.200.000) i remove relatedContent, they don't need to be visible while offline
  if (data && data.relatedContent) {
    data.relatedContent = []
  }
  //window.storageSetItem(response.config.url, response);
  // #IMPORTANT: manually launch an event to update the list of cached menu items
  // Not used for now
  // window.dispatchEvent(new Event('updateMenu'))
}

const getAPIresponse = async (url) => {
  const cachedResponse = window.storageGetItemValue(url);
  const data = cachedResponse.data.data;
  // Getting base64 image from indexeddb and replace it with the url
  if (data && data.contentBody) {
    await window.asyncForEach(data.contentBody, async (body) => {
      if (body.backgroundImage && body.backgroundImage.content.length > 0) {
        const value = await window.idbCustom.loadImage(body.backgroundImage.content);
        body.backgroundImage.content = value;
      }
    })
  }
  if (data && data.collection) {
    await window.asyncForEach(data.collection, async (content) => {
      if (content.previewImage && content.previewImage.content && content.previewImage.content.length > 0) {
        const value = await window.idbCustom.loadImage(content.previewImage.content);
        content.previewImage.content = value;
      }
    })
  }
  return cachedResponse;
}

// #IMPORTANT: register any content or resource viewed in google analytics by name
const setGoogleAnalyticsContentName = (response) => {
  const url = response.config.url;
  if (url.match('Contents/')) {
    const res = response.data.data;
    if (res !== null) {
      const title = res.contentTitle || res.contentCategoryName || ''
      if (title.length > 0) {
        // if it's a content I have to get the category name (exclude generic content)
        if (res.contentTitle && res.contentCategoryID && res.contentCategoryID !== 3) {
          const token = window.storageGetItemValue("Auth-token");
          const config = { headers: { Authorization: token } }
          axios.get(baseURL + 'Contents/Categories/' + res.contentCategoryID, config).then(res => {
            const data = res.data.data;
            // console.log("setGoogleAnalyticsContentName", cleanText(data.contentCategoryName + ' - ' +title))
            ReactGA.set({
              'dimension3': cleanText(data.contentCategoryName + ' - ' + title)
            })
          })
        } else {
          // console.log("setGoogleAnalyticsContentName", cleanText(title))
          ReactGA.set({
            'dimension3': cleanText(title)
          })
        }
      }
    }
  }
}

export default axiosInstance;