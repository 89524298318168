import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import ReactHtmlParser from 'react-html-parser'
import { Modal } from 'react-bootstrap';
import Button from '../_UI/Button/Button';

interface IProps {
    isModalVisible: boolean,
    handleModal: (visible: boolean) => void,
    title: string
}

const ShareLinkModal: React.FC<IProps> = ({ isModalVisible, handleModal, title }) => {


    useEffect(() => {

    }, [])

    const copy = require('clipboard-copy');


    return (
        <>
            <Modal
                show={isModalVisible}
                onHide={() => handleModal(false)}
                className="modal-sharelink">
                <Modal.Header closeButton>
                    <Modal.Title className="u-font-size-12">Share link</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="u-font-size-9 mb-2">{window.location.href}</p>
                    <Button
                        withClass={["primary", "small", "left"]}
                        classes="mr-2"
                        id="btn-copy-link"
                        clicked={() => {
                            copy(window.location.href);
                            let btnCopy = document.getElementById("btn-copy-link");
                            if (btnCopy) btnCopy.innerText = 'Copied to clipboard'
                        }}
                    >Copy link</Button>
                    <Button
                        withClass={["primary", "small"]}
                        clicked={() => {
                            const subject = title.replace(/<\/?[^>]+(>|$)/g, "").replace(/&\/?[^]+(;)/g, "");
                            const body = window.location.href
                            window.location.href = `mailto:?subject=${subject}&body=${body}`
                        }}
                    >Send via email</Button>
                </Modal.Body>
                <Modal.Footer>
                    <Button withClass={["small", "red"]} clicked={() => handleModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ShareLinkModal;