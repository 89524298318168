import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import axiosInstance from 'src/config/axios';
import { TagsTypeRes } from 'src/Models/ResponseModels/Tags';
import history from 'src/history';
import { setIdDescription } from 'src/config/functions';


// INFO:
// TagParentID
// ImageResourceID: picture to load
// Color: hexadecimal
// Visible

interface IProps {
    tag: TagsTypeRes.ITag,
}

const ItemHeader = styled.div`
    ${(props: any) => props.pictureLoaded ? 'padding-top: 0' : ''}
`;

const CategoriesItem = ({ tag }: IProps) => {

    const [picture, setPicture] = useState<string>('');

    useEffect(() => {
        if (tag.tagSettings && tag.tagSettings.imageResourceID) {
            axiosInstance.get('Resources/' + tag.tagSettings.imageResourceID + '/base64')
                .then(res => {
                    const response = res.data;
                    if (response.status === 'success') {
                        if (response.data) {
                            setPicture(response.data.content);
                        }
                    }
                })
        }
    }, [])

    const handleCategoriesClick = () => {
        if (tag.tagSettings && tag.tagSettings.friendlyUrl && tag.tagSettings.friendlyUrl !== '') {
            history.push('/' + tag.tagSettings.friendlyUrl);
            window.scrollTo(0, 0);
        }
    }

    return (
        <div className="categories-item gtm-track-click-home" data-gtmname={setIdDescription(tag.tagDescription)} onClick={() => { handleCategoriesClick() }} id={setIdDescription(tag.tagDescription)} >
            <ItemHeader pictureLoaded={picture.length > 0} className="categories-item-header">
                {
                    picture &&
                    <img src={picture}
                        className="img-fluid categories-item-picture" alt="tag" />
                }
            </ItemHeader>
            <div className="categories-item-name-wrapper">
                <p className="categories-item-name u-font-size-10 mb-0">
                    {tag.tagDescription}
                </p>
            </div>
        </div>

    )
}

export default CategoriesItem;