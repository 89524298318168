import React from 'react';

interface INotify {
    title: string,
    link: string,
    type: string,
    target: string
}

interface IProps {
    notify: INotify
}

const openPWAModal = () => {
    window.dispatchEvent(new CustomEvent('showPWAInfo'));
}

const Notify = ({ notify }: IProps) => {

    switch (notify.type) {
        case 'pwa':
            return <><u onClick={openPWAModal} className="cursor-pointer">Discover our new offline functionality!</u></>
        case 'custom':
            return notify.link !== '' ?
                <u onClick={() => window.open(notify.link, notify.target ? notify.target : '_self')} className="cursor-pointer">{notify.title}</u> :
                <>{notify.title}</>
        default:
            return <>Type notification not setted yet</>
    }

}

export default Notify;