import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal'
import { getCookie, setCookie } from 'src/config/functions';
import Button from '../Button/Button';
import './ModalMadrid.scss';

const ModalMadrid = () => {
    const closeInfoId = 4;
    const [splashScreenInfo, setSplashScreenInfo] = useState<boolean>(getCookie("close-dialog-" + closeInfoId) === 'true' ? false : true);

    const handleCloseOnClick = () => {
        setCookie("close-dialog-" + closeInfoId, true, 2147483647);
        setSplashScreenInfo(false);
    }

    return (
        <>
            {
                splashScreenInfo ?
                    <Modal centered size="xl" show={splashScreenInfo} className="modal-madrid" onHide={() => handleCloseOnClick()} >
                        <Modal.Body className="modal-madrid-body p-0">
                            <FontAwesomeIcon icon={faTimes} size="2x" onClick={() => handleCloseOnClick()} />
                            <img src="/assets/images/what-should-we-do-pop-up.jpg" className='background img-fluid' onClick={() => window.open("https://www.zbsmartspace.com/digital-shoulder-symposium")} />
                            {/* <div className='inner'>
                                <h2>WE ARE CURRENTLY STREAMING LIVE!</h2>
                                <h3>Join the ZBI Digital Symposium<br /><b>Problem Solved!? Advancing Arthroplasty Together</b></h3>
                                <Button withClass={["center"]} classes="madrid-button" clicked={() => window.open("https://www.zbsmartspace.com/problem-solved-digital")}>JOIN LIVE NOW</Button>
                                <p>TO LEARN AND DISCUSS LATEST TRENDS AND TECHNOLOGIES<br />
                                    TO ADDRESS UNMET NEEDS AND CHALLENGES<br />
                                    IN ORTHOPEDIC PATIENT CARE TODAY.</p>
                            </div> */}
                        </Modal.Body>
                    </Modal>
                    :
                    null
            }
        </>
    )
}

export default ModalMadrid;