import React, { useEffect } from 'react'
import Button from './_UI/Button/Button';
import { useAuth0 } from "@auth0/auth0-react";
import axiosInstance from 'src/config/axios';

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <Button withClass={["light", "small"]} clicked={(e) => {
      loginWithRedirect();
    }}>Login</Button>
  )
}

export default LoginButton 