import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import history from "src/history";

const CalendarButton = () => {
    const [show, setShow] = useState(true);
    history.listen((location, action) => {
        setShow(location.pathname !== "/women-in-orthopedics/chat");
    })
    return (
        show ?
            <div className={history.location.pathname !== "/women-in-orthopedics/chat" ? "scroll-calendar-wrapper" : "d-none"}>
                <Link to='/' className="scroll-calendar-link">
                    <div className="scroll-calendar">
                        <FontAwesomeIcon className="scroll-calendar-icon" icon={faCalendarAlt} />
                        <p className="scroll-calendar-text">CALENDAR</p>
                    </div>
                </Link>
            </div> :
            null
    );
}

export default CalendarButton;