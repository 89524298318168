import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faShare, faSearch } from '@fortawesome/free-solid-svg-icons';
import { faFolderOpen } from '@fortawesome/free-regular-svg-icons';
import history from '../../history';
import { isIE } from '../../config/functions';
import { getMenuItems, TmenuItem } from './navMenuData';
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Login from '../Login/Login'
import Registration from '../Registration/Registration'
import CachedContentsPanel from '../CachedContentsPanel/CachedContentsPanel';
import { AppContextConsumer } from "../../context";
import axios from '../../config/axios';
import Button from '../_UI/Button/Button';
import { hideLoader, showLoader } from '../../config/loader';
import LoginButton from '../LoginButton';
import LogoutButton from '../LogoutButton';
import RegisterButton from '../RegisterButton';


interface IProps {
  clicked: Function,
  isHidden: boolean,
}

interface IState {
  menuCached: any
  menu: any,
  events: MyObject[],
  isLoginModalOpened: boolean,
  isRegisterModalOpened: boolean,
  isNavExpanded: boolean,
  isCachePanelActive: boolean,
  isOffline: boolean,
  isSearchBarExpanded: boolean,
}

interface MyObject {
  contentCategoryID: number;
  contentCategoryName: string;
  contentCategoryParent: number;
  friendlyURL: string;
}


const initMenuCached = {
  webinar: false,
}

let timeout: NodeJS.Timeout;
class Navigation extends React.Component<IProps, IState> {

  state: IState = {
    events: [],
    menu: [],
    menuCached: initMenuCached,
    isLoginModalOpened: false,
    isRegisterModalOpened: false,
    isNavExpanded: false,
    isCachePanelActive: false,
    isOffline: false,
    isSearchBarExpanded: false,
  }

  checkOffline = () => {
    if (navigator.onLine !== !this.state.isOffline) {
      this.setState({ isOffline: !navigator.onLine })
    }
  }

  showEvent = (category: MyObject): boolean => {
    // 2020-10-08 => Avenir now visible to everyone
    // if(category.friendlyURL.indexOf('avenir-complete') >= 0){
    //     if(window.userInRole("AvenirViewer_092020")){
    //         return true;
    //     } else {
    //         return false;
    //     }
    // }
    return true;
  }

  // Load menu items with cache info
  setMenu = () => {
    this.setState({ menu: getMenuItems(this.state.events) });
  }

  getCachedPages = () => {
    const apiCalls = Object.keys(window.localStorage);
    this.setState({
      menuCached: {
        webinar: apiCalls.filter(el => el.includes('Contents/Categories/1')).length > 0
      }
    })
  }

  handleSearchValue = () => {
    const inputSearch: HTMLInputElement = document.getElementById("inputSearch") as HTMLInputElement;
    if (inputSearch) {
      this.setState({ isNavExpanded: false })
      const textSearch = encodeURIComponent(inputSearch.value);
      inputSearch.value = '';
      this.setState({ isSearchBarExpanded: false });
      history.push("/search/" + textSearch)
    }
  }

  openLoginModal = (value: boolean) => {
    this.setState({ isLoginModalOpened: value })
  }

  openRegisterModal = (value: boolean) => {
    this.setState({ isRegisterModalOpened: value })
  }

  setNavExpanded = (value: boolean, key: string = '') => {
    this.setState({ isNavExpanded: value, isCachePanelActive: false });
    timeout = setTimeout(() => {
      window.dispatchEvent(new CustomEvent('navExpanded'));
    }, 300);
  }

  toggleCachePanel = (value: boolean) => {
    this.setState({ isCachePanelActive: value, isNavExpanded: false });
  }

  checkUserInRoles = (roles: string[]): boolean => {
    // console.log("checkUserInRoles", roles)
    var resultValue = false;
    roles.forEach(role => {
      // console.log(role, window.userInRole(role));
      if (window.userInRole(role)) {
        resultValue = true;
      }
    })
    return resultValue;
  }

  // manually save logs when opening an external link
  saveContentLog = (key: string) => {
    if (key === 'fracture') {
      axios.get('Contents/fracture-logs').then(res => {

      })
    }
  }

  handleGoBackButton = () => {
    if (history.location.pathname === "/3d-anatomy/model-library") window.history.go(-2);
    else return history.goBack();
  }

  /* To remove at 15th july */
  handleDownloadWioRequests = () => {
    showLoader();
    var req = {
      sorting: [
        {
          fieldName: 'requestDate',
          desc: false
        }
      ],
      pagination: {
        offset: 0,
        limit: 999
      },
      filters: [
        {
          fieldName: "requestArea",
          fieldValue: 'women-in-orthopedics',
          isString: true
        },
      ]
    }
    axios.post('Support/Search', req).then(res => {
      this.download(res.data.data.collection, "WiORequests.csv", "text/csv;charset=UTF-8");
      setTimeout(() => hideLoader(), 200);
    }).catch(e => {
      setTimeout(() => hideLoader(), 200);
      console.log(e);
    });
  }

  handleDownloadWioChatParticipants = () => {
    showLoader();
    const data: any = {
      startDate: new Date('2021-07-15'),
      endDate: new Date(),
      messengerGuid: 'DD3BAA7E-87F5-4108-92BD-AED2B8E972F9'
    };
    axios.post('Stats/MessengerPartecipants', data).then(res => {
      const results: any = res.data.data;
      if (results && results.length > 0) {
        this.download(results, "WiO_Chat_Participants.csv", "text/csv;charset=UTF-8");
      }
      setTimeout(() => hideLoader(), 200);
    }).catch(e => {
      setTimeout(() => hideLoader(), 200);
      console.log(e);
    });
  }

  handleDownloadResidents = () => {
    showLoader();
    axios.post('Stats/Residents').then(res => {
      const results: any = res.data.data;
      if (results && results.length > 0) {
        this.download(results, "ZB_Residents.csv", "text/csv;charset=UTF-8");
      }
      setTimeout(() => hideLoader(), 200);
    }).catch(e => {
      setTimeout(() => hideLoader(), 200);
      console.log(e);
    });
  }

  handleDownloadMemberViewsByMainTag = () => {
    showLoader();
    axios.post('Stats/GetMemberViewsByMainTag', {}).then(res => {
      const results: any = res.data.data;
      if (results && results.length > 0) {
        this.download(results, "UK_Italy_views_last_6_months.csv", "text/csv;charset=UTF-8");
      }
      setTimeout(() => hideLoader(), 200);
    }).catch(e => {
      setTimeout(() => hideLoader(), 200);
      console.log(e);
    });
  }

  handleDownloadGlobalMemberViewsByMainTag = () => {
    showLoader();
    axios.post('Stats/GetGlobalMemberViewsByMainTag', {}).then(res => {
      const results: any = res.data.data;
      if (results && results.length > 0) {
        this.download(results, "Global_views_last_6_months.csv", "text/csv;charset=UTF-8");
      }
      setTimeout(() => hideLoader(), 200);
    }).catch(e => {
      setTimeout(() => hideLoader(), 200);
      console.log(e);
    });
  }

  handleDownloadFacultyAreaViewer = () => {
    showLoader();
    axios.post('Stats/GetFacultyAreaViewer', {}).then(res => {
      const results: any = res.data.data;
      if (results && results.length > 0) {
        this.download(results, "Faculty_area_viewers.csv", "text/csv;charset=UTF-8");
      }
      setTimeout(() => hideLoader(), 200);
    }).catch(e => {
      setTimeout(() => hideLoader(), 200);
      console.log(e);
    });
  }

  handleDownloadFacultyRoomViews = () => {
    showLoader();
    axios.post('Stats/ContentViewersViews', {
      "id": 0,
      "messengerGuid": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      "textValue": "string",
      "startDate": "2024-05-03T16:55:09.386Z",
      "endDate": "2024-05-03T16:55:09.386Z"
    }).then(res => {
      const results: any = res.data.data;
      if (results && results.length > 0) {
        this.download(results, "Faculty_room_stats.csv", "text/csv;charset=UTF-8", true);
      }
      setTimeout(() => hideLoader(), 200);
    }).catch(e => {
      setTimeout(() => hideLoader(), 200);
      console.log(e);
    });
  }


  handleDownloadUserListInterests = () => {
    showLoader();
    axios.post('Stats/UserListInterests', {}).then(res => {
      const results: any = res.data.data;
      if (results && results.length > 0) {
        this.download(results, "User_list_interests.csv", "text/csv;charset=UTF-8");
      }
      setTimeout(() => hideLoader(), 200);
    }).catch(e => {
      setTimeout(() => hideLoader(), 200);
      console.log(e);
    });
  }


  download = (data: any, filename: string, type: string, memberType?: boolean) => {
    var fileData = this.convertJsonToCsv(data, memberType);
    // #IMPORTANT: utf-8 array to csv
    var file = new Blob(["\ufeff" + fileData], { type: type });
    // @ts-ignore
    if (window.navigator.msSaveOrOpenBlob) // IE10+
      // @ts-ignore
      window.navigator.msSaveOrOpenBlob(file, filename);
    else { // Others
      var a = document.createElement("a"),
        url = URL.createObjectURL(file);
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      setTimeout(function () {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 0);
    }
  }

  convertJsonToCsv = (json: any, memberType?: boolean) => {
    const JOINCHAR = ';';
    var fields = Object.keys(json[0])
    fields = fields.filter(function (el: string) {
      if (memberType) {
        return (el != "supportRequestID" &&
          el != "requestConfirm" &&
          el != "requestExpire" &&
          el != "requestStatus" &&
          el != "approved" &&
          el != "message" &&
          el != "additionalField02" &&
          el != "additionalField03" &&
          el != "additionalField04" &&
          el != "additionalField05")
      }

      return (el != "supportRequestID" &&
        el != "memberType" &&
        el != "requestConfirm" &&
        el != "requestExpire" &&
        el != "requestStatus" &&
        el != "approved" &&
        el != "message" &&
        el != "additionalField02" &&
        el != "additionalField03" &&
        el != "additionalField04" &&
        el != "additionalField05")
    });

    var replacer = function (key: any, value: any) { return value === null ? '' : value }
    var csv = json.map(function (row: any) {
      return fields.map(function (fieldName) {

        let fieldValue = row[fieldName];
        return JSON.stringify(fieldValue, replacer)
      }).join(JOINCHAR)
    })
    var strFields = fields.join(JOINCHAR);
    strFields = strFields.replace('additionalField01', 'pre-registration');
    strFields = strFields.replace('privateChatStarted', 'N° private chat started');
    strFields = strFields.replace('privateChatJoined', 'N° private chat joined');
    csv.unshift(strFields) // add header column

    return (csv.join('\r\n'))
  }

  componentWillUnmount() {
    if (timeout) {
      clearTimeout(timeout);
    }
  }

  render() {

    const { menu, isLoginModalOpened, isRegisterModalOpened, isNavExpanded, isCachePanelActive, isOffline } = this.state;
    const { isHidden, clicked } = this.props;

    return (
      <>
        {isLoginModalOpened ? <Login type="modal" isModalOpened={isLoginModalOpened} openModal={this.openLoginModal} /> : null}
        {isRegisterModalOpened ? <Registration type="modal" isModalOpened={isRegisterModalOpened} openModal={this.openRegisterModal} /> : null}

        {isHidden ? null :
          <AppContextConsumer>
            {
              appContext => appContext && (
                <Navbar bg="light" expand="xl" className="header-nav" expanded={isNavExpanded} id="main-nav">
                  {/* cache panel */}
                  {isCachePanelActive ? <CachedContentsPanel close={() => this.toggleCachePanel(false)} /> : null}
                  <Navbar.Brand>
                    {/* brand image */}
                    <Link to={{ pathname: `/home-page` }}>
                      <img src={"/assets/images/zbi-logo.svg"} alt="zbin" className="header-nav-brand" />
                    </Link>
                    {/* back button */}
                    {appContext.authToken === null ? null :
                      <Button
                        id="back-nav"
                        withClass={["primary", "small"]}
                        classes="header-nav-back ml-3 back-nav-none"
                        icon="faChevronLeft"
                        iconPosition="left"
                        clicked={(e) => { this.handleGoBackButton() }}>Back
                      </Button>
                    }
                    {
                      !isIE() && window.storageGetItemValue("Auth-token") ?
                        <FontAwesomeIcon
                          icon={faFolderOpen}
                          title="Cached contents"
                          className="header-nav-pwa header-nav-pwa--hidden-menu d-none"
                          onClick={() => this.toggleCachePanel(!isCachePanelActive)}
                        /> :
                        null
                    }
                  </Navbar.Brand>
                  {appContext.authToken === null ? null : <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => this.setNavExpanded(!isNavExpanded)} />}
                  {appContext.authToken === null ?
                    <div className="header-nav-item header-nav-item-login">
                      <a href={`${process.env.REACT_APP_AUTH0_BASE_URL}/learning-pathways`}
                        style={{ cursor: "pointer", marginTop: "0.2rem" }}>
                        <img src={"/assets/images/learning-pathways-logo-extend.png"} alt="learning pathways" className="header-nav-brand" />
                      </a>
                      <div className="not-logged">
                        <RegisterButton />
                        <LoginButton />
                      </div>
                    </div>
                    :
                    <Navbar.Collapse id="basic-navbar-nav" className="bg-light">
                      <Nav className="mr-auto">
                        {/* LOGIN AND PERSONAL AREA */}
                        <div className="header-nav-item header-nav-item-login">
                          <a href={`${process.env.REACT_APP_AUTH0_BASE_URL}/learning-pathways`}
                            style={{ cursor: "pointer", marginTop: "0.2rem" }}>
                            <img src={"/assets/images/learning-pathways-logo-extend.png"} alt="learning pathways" className="header-nav-brand" />
                          </a>
                          {
                            !isIE() && window.storageGetItemValue("Auth-token") ?
                              <FontAwesomeIcon
                                icon={faFolderOpen}
                                title="Cached contents"
                                className="header-nav-pwa header-nav-pwa--extended-menu"
                                onClick={() => this.toggleCachePanel(!isCachePanelActive)}
                              /> :
                              null
                          }
                          {
                            appContext.authToken === null ?
                              <div className="not-logged">
                                <RegisterButton />
                                <LoginButton />
                              </div>
                              :
                              <div className="personal-menu">
                                <div className="user-wrapper">
                                  {
                                    !isOffline && (window.userInRole("UserAdmin") || window.userInRole("UserEditor") || window.userInRole("UserApproval") || window.userInRole("PollAdmin") || window.userInRole("StatsAdmin") || window.userInRole("CVentAdmin") ||
                                      window.userInRole("UserAdmin") || (appContext.userData && (appContext.userData.memberTypeID === 2 || appContext.userData.memberID === 3896))) ?
                                      <NavDropdown title="Reserved Area" id="reserved-area">
                                        {/* Users list */}
                                        {window.userInRole("UserAdmin") || window.userInRole("UserEditor") || window.userInRole("UserApproval") ? <NavDropdown.Item onClick={() => { this.setNavExpanded(false); history.push("/users-list") }}>Users list</NavDropdown.Item> : null}
                                        {/* ZBID */}
                                        {/* {window.userInRole("UserAdmin") || window.userInRole("UserEditor") || window.userInRole("UserApproval") ? <NavDropdown.Item onClick={() => { this.setNavExpanded(false); window.open("https://auth.zbinetworkemea.com/Administration", "_blank") }}>ZBID Users management</NavDropdown.Item> : null} */}
                                        {/* New user */}
                                        {window.userInRole("UserAdmin") || window.userInRole("UserEditor") ? <NavDropdown.Item onClick={() => { this.setNavExpanded(false); history.push("/add-user") }}>New user</NavDropdown.Item> : null}
                                        {/* show Notifications */}
                                        {window.userInRole("NotificationAdmin") && window.userInRole("NotificationViewer") ? <NavDropdown.Item onClick={() => { this.setNavExpanded(false); history.push("/notifications-manager") }}>Notifications Manager</NavDropdown.Item> : null}
                                        {/* show Statistics */}
                                        {window.userInRole("StatsAdmin") ? <NavDropdown.Item onClick={() => { this.setNavExpanded(false); history.push("/statistics") }}>Statistics</NavDropdown.Item> : null}
                                        {/* show Statistics */}
                                        {window.userInRole("SupportAdmin") ? <NavDropdown.Item onClick={() => { this.setNavExpanded(false); history.push("/3d-anatomy-requests") }}>3D Anatomy requests</NavDropdown.Item> : null}
                                        {/* show Cvent */}
                                        {window.userInRole("UserAdmin") || window.userInRole("CVentAdmin") ? <NavDropdown.Item onClick={() => { this.setNavExpanded(false); history.push("/members/import") }}>Cvent</NavDropdown.Item> : null}
                                        {/* show Poll  */}
                                        {window.userInRole("PollAdmin") ? <NavDropdown.Item onClick={() => { this.setNavExpanded(false); history.push("/polls-admin") }}>Polls list</NavDropdown.Item> : null}
                                        {/* show Mailchimp panel  */}
                                        {window.userInRole("MailchimpAdmin") ? <NavDropdown.Item onClick={() => { this.setNavExpanded(false); history.push("/mailchimp-panel") }}>Mailchimp panel</NavDropdown.Item> : null}
                                        {/* show Submit a new congress */}
                                        {/* {appContext.userData && (appContext.userData.memberTypeID === 2 || appContext.userData.memberTypeID === 3) ? <NavDropdown.Item onClick={() => { this.setNavExpanded(false); history.push("/third-party-congress-promotion") }}>Submit a new congress</NavDropdown.Item> : null}
                                        {window.userInRole("SupportTpcAdmin") ? <NavDropdown.Item onClick={() => { this.setNavExpanded(false); history.push("/third-party-congress-requests") }}>Tpc requests</NavDropdown.Item> : null} */}
                                        {/* download Wio chat partecipants */}
                                        {window.userInRole("SupportViewer") ? <NavDropdown.Item onClick={() => { this.setNavExpanded(false); this.handleDownloadWioChatParticipants(); }}>WiO chat participants</NavDropdown.Item> : null}
                                        {/* download ZB residents */}
                                        {window.userInRole("SupportAdmin") ? <NavDropdown.Item onClick={() => { this.setNavExpanded(false); this.handleDownloadResidents(); }}>ZB Residents</NavDropdown.Item> : null}
                                        {/* Member views by main tag */}
                                        {appContext.userData && (appContext.userData.memberID === 3896 || appContext.userData.memberID === 20495 || appContext.userData.memberTypeID === 3) ? <NavDropdown.Item onClick={() => { this.setNavExpanded(false); this.handleDownloadMemberViewsByMainTag(); }}>UK - Italy views last 6 months</NavDropdown.Item> : null}
                                        {/* Member views */}
                                        {appContext.userData && (appContext.userData.memberID === 3896 || appContext.userData.memberID === 20495 || appContext.userData.memberTypeID === 3) ? <NavDropdown.Item onClick={() => { this.setNavExpanded(false); this.handleDownloadGlobalMemberViewsByMainTag(); }}>Global views last 6 months</NavDropdown.Item> : null}
                                        {/* Faculty area viewers */}
                                        {window.userInRole("StatsAdmin") ? <NavDropdown.Item onClick={() => { this.setNavExpanded(false); this.handleDownloadFacultyAreaViewer(); }}>Faculty room viewers</NavDropdown.Item> : null}
                                        {/* Faculty room stats */}
                                        {(window.userInRole("StatsAdmin") || window.userInRole("StatsViewer")) ? <NavDropdown.Item onClick={() => { this.setNavExpanded(false); this.handleDownloadFacultyRoomViews(); }}>Faculty room stats</NavDropdown.Item> : null}
                                        {/* User List interests */}
                                        {(window.userInRole("StatsAdmin") || window.userInRole("StatsViewer")) ? <NavDropdown.Item onClick={() => { this.setNavExpanded(false); this.handleDownloadUserListInterests(); }}>User List Interests</NavDropdown.Item> : null}
                                        {/* Calendar control panel */}
                                        {window.userInRole("CalendarEditor") ? <NavDropdown.Item onClick={() => { this.setNavExpanded(false); history.push("/calendar-manager") }}>Calendar Manager</NavDropdown.Item> : null}
                                        {(appContext.userData && (appContext.userData.memberTypeID === 2 || appContext.userData.memberTypeID === 3)) || window.userInRole("SupportTpcAdmin") ? <NavDropdown.Item onClick={() => { this.setNavExpanded(false); history.push("/tpc-manager") }}>Tpc Manager</NavDropdown.Item> : null}
                                        {window.userInRole("ContentAdmin") || window.userInRole("ContentEditor") ? <NavDropdown.Item onClick={() => { this.setNavExpanded(false); history.push("/cpd-points") }}>CPD Points</NavDropdown.Item> : null}
                                        {window.userInRole("ContentAdmin") || window.userInRole("ContentEditor") ? <NavDropdown.Item onClick={() => { this.setNavExpanded(false); history.push("/content-manager") }}>Content manager</NavDropdown.Item> : null}
                                      </NavDropdown> :
                                      null
                                  }
                                  {
                                    !isOffline ?
                                      <>
                                        <Nav.Link onClick={() => { this.setNavExpanded(false); history.push("/edit-profile") }}>
                                          <FontAwesomeIcon icon={faUser} title="Edit profile" />
                                        </Nav.Link>
                                        <LogoutButton />
                                      </>
                                      :
                                      null
                                  }
                                </div>
                                <div className="search-wrapper">
                                  {
                                    !isOffline ?
                                      <div className={"search" + (this.state.isSearchBarExpanded ? " expanded" : "")}>
                                        <div className="form-control bg-light search-input-wrapper search-input-wrapper-closed" >
                                          <input id="inputSearch" placeholder="Search..." type="text" className="search-input w-100 bg-light" onKeyDown={(e) => {
                                            if (e.keyCode === 13) {
                                              this.handleSearchValue();
                                            }
                                          }} />
                                          <FontAwesomeIcon icon={faSearch} className="search-input-icon" title="Search" onClick={this.handleSearchValue} />
                                        </div>
                                        <Button icon={this.state.isSearchBarExpanded ? "faTimes" : "faSearch"} withClass={["primary"]} classes="search-button" clicked={() => {
                                          this.setState(prevState => ({ isSearchBarExpanded: !prevState.isSearchBarExpanded }), () => {
                                            const inputSearch: HTMLInputElement = document.getElementById("inputSearch") as HTMLInputElement;
                                            if (inputSearch) {
                                              inputSearch.focus();
                                            }
                                          });
                                        }} />
                                      </div>
                                      :
                                      null
                                  }
                                </div>
                              </div>
                          }

                        </div>
                      </Nav>
                    </Navbar.Collapse>
                  }
                </Navbar>
              )
            }
          </AppContextConsumer>
        }
      </>
    )
  }

}


export default Navigation;

