import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faFileSignature } from '@fortawesome/free-solid-svg-icons';


const SplashScreenHCPSurvey = () => {

    const [isModalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        // if(!window.storageGetItemValue('isModalHCPSurveyViewed')){
        //     setModalOpen(true);
        // }
    }, [])

    const handleCloseModal = () => {
        setModalOpen(false);
        window.storageSetItem('isModalHCPSurveyViewed', true);
    }

    return (
        <Modal
            size="lg"
            centered
            show={isModalOpen}
            onHide={handleCloseModal}
            className="splash-screen-pwa"
        >
            <Modal.Body className="text-center p-sm-5 p-4">
                <FontAwesomeIcon icon={faTimes} className="splash-screen-pwa__close" onClick={handleCloseModal} />
                <header>
                    <h1>
                        <p className="u-font-size-14 color-blue font-weight-bold mb-1">YOUR FEEDBACK</p>
                        <p className="u-font-size-14 color-blue font-weight-bold mb-3">IS IMPORTANT TO US!</p>
                    </h1>
                </header>
                <br/>
                <div className="splash-screen-pwa__block">
                    <p className="u-font-size-10 mb-4">Please let us know your opinion on <br/><b className="color-blue">our digital education offer &amp; what more we can do to improve</b></p>
                    <div className="splash-screen-pwa__survey u-font-size-11 mb-4">
                        <a className="splash-screen-pwa__link" href="https://www.surveymonkey.com/r/59P623W" target="_blank">Click here</a>
                        <FontAwesomeIcon className="splash-screen-pwa__survey-icon" icon={faFileSignature} />
                        <p className="u-font-size-8 font-italic">Survey takes approx. 2min</p>
                    </div>
                    <p className="u-font-size-10 font-weight-bold">Thank you in advance from your <i>ZBI EMEA Team</i></p>
                </div>
                <br/>
                <br/>
            </Modal.Body>
        </Modal>
    )
}

export default SplashScreenHCPSurvey;