import React, { useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { setCookie } from 'src/config/functions';
import { faStar as faStarEmpty } from '@fortawesome/free-regular-svg-icons';

interface IProps {
    id?: number,
    title?: string,
    text: string,
    classes?: string,
    arrowTop?: boolean,
    arrowBottom?: boolean,
    setDialogBoxVisible: (value: boolean) => void
}

const DialogBox = ({ id, title, text, classes, arrowTop = false, arrowBottom = false, setDialogBoxVisible }: IProps) => {

    const handleCloseOnClick = () => {
        setCookie("close-dialog-" + id, true, 2147483647);
        setDialogBoxVisible(false);
    }

    return (
        <div className={`dialog-box-wrapper ${classes}`}>
            <FontAwesomeIcon icon={faTimes} className="dialog-box__close" onClick={handleCloseOnClick} />
            <div className="dialog-box-content">
                {
                    title && title !== '' &&
                    <h1 className="dialog-box__title u-font-size-9">
                        {ReactHtmlParser(title)}
                    </h1>
                }
                <p className="dialog-box__text u-font-size-8">
                    {ReactHtmlParser(text)}
                </p>
            </div>
            {
                arrowTop &&
                <>
                    <div className="dialog-box__arrow-top" />
                    <div className="dialog-box__arrow-left" />
                </>
            }
            {
                arrowBottom &&
                <div className="dialog-box__arrow-bottom" />
            }
        </div>
    )
}

export default DialogBox;