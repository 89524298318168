import React, { useState, useEffect } from 'react';

type StyleProp = "small" | "large" | "primary" | "disabled";

interface IProps {
    text?: string,
    isActive: boolean,
    className?: string,
    disabled?: boolean,
    onClick: (active: boolean) => void
}

const Toggle: React.FC<IProps> = ({ text = '', isActive, className = '', onClick, disabled = false }: IProps) => {

    // Manage component active state from parent
    // const [active, setActive] = useState<boolean>(false);
    // useEffect(() => {
    //     setActive(isActive);
    // }, [isActive])
    
    const clickHandler = () => {
        // !disabled && setActive(!active);
        !disabled && onClick(!isActive);
    }

    return (
        <label className={`toggle ${className}`} htmlFor="" onClick={clickHandler} >
            <span className="toggle__text u-font-size-9 mr-2">{text}</span>
            <div className="switch">
                <input type="checkbox" className="switch__checkbox" checked={isActive} readOnly />
                <span className="switch__slider"></span>
            </div>
        </label>
    );
    
}

export default Toggle;