import React from 'react';
import { Link, match } from 'react-router-dom';
import loadPage from '../../Components/_HOC/loadPage';
import Button from 'src/Components/_UI/Button/Button';

interface DetailParams {
    key: string;
}

interface IProps {
    images: {[key: string]: string},
    match?: match<DetailParams>,
}

const imagesToCache = {
    banner: "assets/images/carousel/banner_webinar_new-1920.jpg",
}

const EventRsvp = ({ images }: IProps) => {

    return (
        <div className="container">
            <div className="row">
                <img src={images.banner} alt="event rsvp" className="img-fluid" />
            </div>
            <div className="row text-center mt-6">
                <div className="col-12">
                    <h2 className="color-blue u-font-size-20">Thanks for accepting our invitation!</h2>
                    <Link to="/" className="my-5 d-inline-block">
                        <Button withClass={["primary","center"]} icon="faChevronRight">Go to the homepage</Button>
                    </Link>
                </div>
            </div>
        </div>
    )

}

export default loadPage(
    EventRsvp,
    {
        url: 'Members/ContentRSVP',
        method: 'POST',
        data: {
            contentID: "{KEY}"
        }
    },
    imagesToCache
);