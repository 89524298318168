import React from 'react';
import ReactJWPlayer from 'react-jw-player';

import './JWPlayer.scss'

interface IState {
    videoTitle: string
}

interface MyFile {
    file: string
    image: string
}

interface IProps {
    playlist: MyFile[],
    playerId: string,
    aspectRatio?: string,
    autoPlay?: boolean
}

class ReactJWPlayerContainer extends React.Component<IProps,IState> {
    constructor(props: IProps) {
      super(props);
      this.state = {
        videoTitle: '',
      };
   
      this.onAdPlay = this.onAdPlay.bind(this);
      this.onReady = this.onReady.bind(this);
      this.onVideoLoad = this.onVideoLoad.bind(this);
    }
    onReady(event: Event) {
        const { playerId, aspectRatio } = this.props;

        const player = document.getElementById(playerId);
        if(player){
            if(aspectRatio){
                player.classList.add("jw-flag-aspect-mode")
                const playerAspect = document.querySelector("#"+playerId+" .jw-aspect");
                if(aspectRatio === "16:9" && playerAspect)
                    playerAspect.classList.add("jw-aspect-16-9")
                if(aspectRatio === "1:1" && playerAspect)
                    playerAspect.classList.add("jw-aspect-1-1")    
            }
        }
    }
    onAdPlay(event: Event) {
      // track the ad play here
    }
    onVideoLoad(event: Event) {

    }
    render() {

        const { playerId, playlist, aspectRatio = "initial", autoPlay = false } = this.props; 

        return (
            <div className='react-jw-player-container'>
            { this.state.videoTitle ? <h1>{ this.state.videoTitle }</h1> : null }
            <ReactJWPlayer
                licenseKey='p4r/14hM6szY7dUEExNU3R92JaIdX4OKXemfXw=='
                playerScript='/assets/plugins/jwplayer/jwplayer.js'
                onAdPlay={this.onAdPlay}
                onReady={this.onReady}
                onVideoLoad={this.onVideoLoad}
                playerId={playerId}
                playlist={playlist}
                aspectRatio={aspectRatio}
                isAutoPlay={autoPlay}
                // customProps={{ controls: false }}
            />
            </div>
        );
    }
  }

  export default ReactJWPlayerContainer;