import React from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import axiosModule from 'axios';

import Button from '../_UI/Button/Button'
import axios from '../../config/axios';
import { validateEmail } from '../../config/functions';


interface IProps {

}

interface IState {
    email: string,
    captcha: string,
    confirm: boolean,
    error: {
        email: boolean,
        captcha: boolean,
        confirm: boolean,
        status: number,
    },
    completed: boolean,
}

const initError = {
    email: false,
    confirm: false,
    captcha: false,
    apiCall: '',
    status: 0
}

const recaptchaRef: any = React.createRef();
const KEY = process.env.NODE_ENV === 'development' ? "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI" : "6Ld1394ZAAAAADxx-Ycx4Flwfwf0yFSbhE-_goKN"

class Unsubscribe extends React.Component<IProps,IState> {

    state = {
        email: '',
        captcha: '',
        confirm: false,
        error: {...initError},
        completed: false,
    }

    componentDidMount() {

    }

    handleChange = (value: string | null) => {
        console.log("Captcha value:", value);
        if(value){
            this.setState({ captcha: value });
        } else {    // expired
            this.setState({ captcha: '' });
        }
    };

    validatePassword = (psw1: string, psw2: string) => {
        if(psw1.length >= 8 && psw1.length <= 50 && psw2.length >= 8 && psw2.length <= 50 && psw1 === psw2)
            return true
        return false
    }

    handleButtonClick = async () => {
        const { email, captcha, confirm } = this.state;

        let error = {...initError};
        let completed = true;
        
        if(!validateEmail(email)){
            error.email = true;
            completed = false;
        }

        if(!confirm){
            error.confirm = true;
            completed = false;
        }

        if(!captcha){
            error.captcha = true;
            completed = false;
        } else {
            // TODO -- test online
            const res = await axiosModule.post('https://www.google.com/recaptcha/api/siteverify',{
                secret: '6Ld1394ZAAAAAK-WRL8pX0dhJU3xI7sDkTSFGEf4',
                response: captcha
            })
            console.log(res);
        }

        if(!completed)
            this.setState({ error, completed });   
        else {
            axios.post('Members/MLUnsubscribe', {
                email: email,
                confirm: true
            }).then(res => {
                const response = res.data;
                if(response.status === "success"){
                    this.setState({ error, completed: true });
                } else {
                    error.apiCall = response.message;
                    this.setState({ error, completed: false });
                }
            })
        } 
    }

    render() {

        const { email, captcha, error, completed } = this.state;

        return (
            <div className="unsubscribe">
                <div className="unsubscribe-header text-center">
                    <h3 className="u-font-size-16 mb-0">Update your subscription</h3>
                </div>
                <div className="unsubscribe-body">
                    <div className="col-md-12">
                        <div className="input-group unsubscribe-mail-wrapper mb-3">
                            <input
                                type="email" name="email" placeholder="email"
                                className="form-control u-font-size-10" aria-label="Email" aria-describedby="basic-addon1"
                                value={email}
                                onChange={(e) => { this.setState({ email: e.target.value }) }}
                            />
                            <div className="input-group-append">
                                <span className="input-group-text u-font-size-10" id="basic-addon1">Email</span>
                            </div>
                            <div className={`invalid-feedback ${error.email ? "d-block" : ""}`}>You have entered an invalid email address</div>
                        </div>
                        <div className="form-group unsubscribe-check-wrapper mb-4">
                            <label className="mb-0">
                                <input 
                                    type="checkbox" name="unsubscribeCheck" className="mr-2"
                                    defaultChecked={this.state.confirm}
                                    onChange={() => { this.setState({ confirm: !this.state.confirm }) }}
                                />
                                I do not wish to receive any email
                            </label>
                            <div className={`invalid-feedback ${error.confirm ? "d-block" : ""}`}>This field is required</div>
                        </div>
                        <div className="unsubscribe-captcha-wrapper mb-4">
                            <ReCAPTCHA
                                className="unsubscribe-captcha u-flex-center"
                                ref={recaptchaRef}
                                sitekey={KEY}
                                size="normal"
                                theme="light"
                                onChange={this.handleChange}
                            />
                            <div className={`invalid-feedback text-center ${error.captcha ? "d-block" : ""}`}>This field is required</div>
                        </div>
                        <Button withClass={["primary","center"]} classes="mb-3" clicked={this.handleButtonClick}>Unsubscribe</Button>
                    </div>
                </div>
                <div className={`valid-feedback u-font-size-10 mb-3 text-center ${completed ? "d-block" : ""}`}>Your subscription has been removed successfully!</div>
                <div className={`invalid-feedback u-font-size-10 mb-3 text-center ${error.apiCall.length > 0 ? "d-block" : ""}`}>{error.apiCall}</div>
                <div className={`invalid-feedback u-font-size-10 mb-3 text-center ${error.status === 404 ? "d-block" : ""}`}>Network error. Please check your internet connection and try again.</div>
                <div className={`invalid-feedback u-font-size-10 mb-3 text-center ${error.status !== 0 && error.status !== 404 ? "d-block" : ""}`}>An error has occurred.</div>
            </div>
        );
    }
    
}

export default Unsubscribe;